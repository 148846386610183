import styled from "styled-components"
import type { ListFileItem } from "types"
import { ImageElement } from "./components/ImageElement"
import { DocumentElement } from "./components/DocumentElement"
import { VideoElement } from "./components/VideoElement"
import { fadeIn } from "jsx/styles/Keyframes"

export const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: 500ms ease;

  background-color: var(--color-grey-50);
  overflow: hidden;

  height: 100%;
  width: 100%;
`

export const StyledArticle = styled.article`
  opacity: 0;
  animation: ${fadeIn} 1s;
  animation-delay: 0.5;
  animation-fill-mode: forwards;

  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  min-height: 200px;
  position: relative;

  * {
    box-sizing: border-box;
    transition: all 0.45s ease;
  }
`

const StyledDetails = styled.div`
  padding-top: 8px;
  height: calc(40px - 8px);
`

const StyledTitle = styled.h4`
  color: var(--text-body-color);
  font-size: 0.875rem;
  font-weight: 700;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;
`

type Props = {
  readonly item: ListFileItem

  /** Methods */
  onClickView: (id: string) => void
}

const IMAGE_TYPES = ["jpeg", "jpg", "png"]
const DOCUMENT_TYPES = ["pdf", "eps", "xls", "xlsx", "doc", "docx", "ppt"]

export const ListMenuItem = ({ item, onClickView }: Props) => {
  let innerContent: JSX.Element | null = null

  const onClickShowItem = () => {
    onClickView(item.id)
  }

  if (IMAGE_TYPES.includes(item.file_extension)) {
    innerContent = <ImageElement preview={item.preview} />
  } else if (DOCUMENT_TYPES.includes(item.file_extension)) {
    innerContent = (
      <DocumentElement mimeType={item.mime_type} preview={item.preview} />
    )
  } else if (item.file_extension === "m4v") {
    innerContent = (
      <VideoElement
        preview={item.preview}
        fileExtension={item.file_extension}
      />
    )
  } else {
    return null
  }

  const filename = `${item.filename}.${item.file_extension}`

  return (
    <StyledArticle onClick={onClickShowItem}>
      <StyledOverlay />
      {innerContent}
      <StyledDetails>
        <StyledTitle>{filename}</StyledTitle>
      </StyledDetails>
    </StyledArticle>
  )
}
