import { StyledContent, StyledH1, StyledSubTitle } from "./Header.styles"

type Props = {
  readonly title: string
  readonly subTitle?: string
}

export const Header = ({ title, subTitle }: Props) => (
  <StyledContent>
    <StyledH1>{title}</StyledH1>
    {subTitle && <StyledSubTitle>{subTitle}</StyledSubTitle>}
  </StyledContent>
)
