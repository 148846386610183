import { BaseComponent } from "./BaseComponent"

type Props = BaseComponent

export const PlayButton = ({ className }: Props) => (
  <svg
    className={className}
    viewBox="0 0 200 200"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="100"
      cy="100"
      r="90"
      fill="none"
      strokeWidth="15"
      stroke="#fff"
    />
    <polygon points="70, 55 70, 145 145, 100" fill="#fff" />
  </svg>
)
