import { SetState } from "zustand"
import { Treeview } from "types/Treeview"
import { sortJSONArray } from "functions/sorting"
import { State } from "./State"

export const sortRecursiveLists = (array) => {
  const localArray = array
  for (let i = 0; i < localArray.length; i++) {
    const node = localArray[i]
    if (node.nodes && node.nodes.length > 0) {
      // Sort array
      node.nodes.sort(sortJSONArray("text", "asc"))
      // Keep moving to new children
      node.nodes = sortRecursiveLists(node.nodes)
    }
  }
  return localArray
}

// Sort nodes and add treeview to store
export const addToStore = async (set: SetState<State>, data: Treeview) => {
  if (data?.nodes && data.nodes.length > 0) {
    // Sort first level
    data.nodes.sort(sortJSONArray("name", "asc"))
    // Sort levels
    sortRecursiveLists(data.nodes)
    set({ treeview: data })
  }
}
