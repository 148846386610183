import styled from "styled-components"
import { CloseIcon } from "icons"

export const StyledFigure = styled.figure`
  display: block;
  cursor: pointer;
  padding: 4px;
`

export const StyledCloseIcon = styled(CloseIcon)`
  display: block;
  color: var(--navbar-icon-color);
  height: 24px;
  opacity: 0.8;
  transition: opacity 200ms ease-in-out;
  width: 24px;

  &:hover {
    opacity: 1;
  }
`

export const FullScreenLayer = styled.div`
  background: #fff;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 300;
`

export const StyledHeader = styled.header`
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  height: 68px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
  width: calc(100% - 40px);
`

export const StyledRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`

export const StyledHidePanel = styled.div`
  cursor: pointer;
  padding-right: 1.5rem;
  user-select: none;
`

export const StyledHidePanelBox = styled.div`
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 8px 12px;

  align-items: center;
  display: flex;
  transition: all 200ms ease-in-out;

  svg {
    color: #374151;
    padding-right: 10px;
    height: 16px;
    width: 16px;
  }

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`

export const StyledHidePanelText = styled.p`
  color: #374151;
  font-size: 13px;
  font-weight: 600;
`

export const StyledContent = styled.div`
  height: calc(100vh - 68px);
`

export const StyledIconBox = styled.div`
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-left: 12px;
  padding: 6px 10px;
  position: relative;
  transition: stroke 100ms ease-in;

  &:hover {
    border: 1px solid var(--border-input-hover);
  }

  svg {
    display: block;
    height: 24px;
    width: 22px;

    stroke: var(--color-grey-600);
    transition: stroke 100ms ease-in;
  }
`

export const StyledTooltip = styled.p`
  content: attr(data-text);

  background-color: #242838;
  border: 1px solid #242838;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  opacity: 0;
  padding: 6px;
  position: absolute;
  text-align: center;
  white-space: nowrap;
  transition: opacity 50ms ease-in;
  visibility: hidden;
  width: auto;
  z-index: 100;

  top: 65%;
  transform: translateY(65%);
  left: -70%;

  ${StyledIconBox}:hover & {
    opacity: 1;
    visibility: visible;
  }
`
