import { create } from "zustand"
import { createTrackedSelector } from "react-tracked"
import { State } from "./State"
import { add } from "./add"
import { clear } from "./clear"
import { load } from "./load"
import { remove } from "./remove"

export const useMediabagStore = createTrackedSelector(
  create<State>((set, get) => ({
    amount: 0,
    items: [],

    // Methods
    load: () => load(set),
    add: (fileId, resolutionId) => add(set, get, fileId, resolutionId),
    clear: () => clear(set),
    remove: (fileId) => remove(set, get, fileId),
  })),
)
