import styled from "styled-components"

export const StyledLoaderContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  min-height: 150px;
`

export const StyledInvalidToken = styled.div`
  padding: 1rem 0;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
  }
`
