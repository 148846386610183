import { SetState } from "zustand"
import { ORDER } from "types/List"
import { State } from "./State"
import { getListConfig } from "./utils/config"

// Load config from cookie
export const initLoad = (set: SetState<State>) => {
  const config = getListConfig()

  let orderBy = ORDER.LATEST
  if (config.orderBy === ORDER.NAME_ASC) {
    orderBy = ORDER.NAME_ASC
  } else if (config.orderBy === ORDER.NAME_DESC) {
    orderBy = ORDER.NAME_DESC
  } else if (config.orderBy === ORDER.LATEST) {
    orderBy = ORDER.LATEST
  }

  // Update store with values from config
  set({ orderBy })
}
