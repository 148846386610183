type Props = {
  className?: string
}

export const WordIcon = ({ className }: Props) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 512 512">
    <path
      d="M490.17 19.2H140.9c-12.05 0-21.83 9.72-21.83 21.7v96.7l202.42 59.2L512 137.6V40.9c0-11.99-9.77-21.7-21.83-21.7z"
      fill="#41a5ee"
    />
    <path
      d="M512 137.6H119.07V256l202.42 35.52L512 256V137.6z"
      fill="#2b7cd3"
    />
    <path
      d="M119.07 256v118.4l190.51 23.68L512 374.4V256H119.07z"
      fill="#185abd"
    />
    <path
      d="M140.9 492.8h349.28c12.05 0 21.83-9.72 21.83-21.7v-96.7H119.07v96.7c0 11.99 9.77 21.7 21.83 21.7z"
      fill="#103f91"
    />
    <path
      d="M263.94 113.92H119.07v296h144.87c12.04-.04 21.79-9.73 21.83-21.7v-252.6c-.04-11.97-9.79-21.66-21.83-21.7z"
      opacity="0.1"
      enableBackground="new"
    />
    <path
      d="M252.04 125.76H119.07v296h132.97c12.04-.04 21.79-9.73 21.83-21.7v-252.6c-.05-11.97-9.8-21.66-21.83-21.7z"
      opacity="0.2"
      enableBackground="new"
    />
    <path
      d="M252.04 125.76H119.07v272.32h132.97c12.04-.04 21.79-9.73 21.83-21.7V147.46c-.05-11.97-9.8-21.66-21.83-21.7z"
      opacity="0.2"
    />
    <path
      d="M240.13 125.76H119.07v272.32h121.06c12.04-.04 21.79-9.73 21.83-21.7V147.46c-.05-11.97-9.79-21.66-21.83-21.7z"
      enableBackground="new"
    />
    <linearGradient
      id="SVGID_1_"
      x1="45.818"
      x2="216.136"
      y1="-1083.492"
      y2="-788.508"
      gradientTransform="translate(0 1192)"
      gradientUnits="userSpaceOnUse">
      <stop offset="0" style={{ stopColor: "#2368c4" }} />
      <stop offset=".5" style={{ stopColor: "#1a5dbe" }} />
      <stop offset="1" style={{ stopColor: "#1146ac" }} />
    </linearGradient>
    <path
      d="M21.83 125.76h218.3c12.05 0 21.83 9.72 21.83 21.7v217.08c0 11.99-9.77 21.7-21.83 21.7H21.83C9.77 386.24 0 376.52 0 364.54V147.46c0-11.98 9.77-21.7 21.83-21.7z"
      fill="url(#SVGID_1_)"
    />
    <path
      d="M89.56 292.21c.43 3.35.71 6.26.85 8.76h.5c.19-2.37.59-5.22 1.19-8.56.6-3.34 1.15-6.16 1.63-8.47l22.96-98.49h29.68l23.81 97.01c1.38 6.03 2.37 12.15 2.96 18.3h.39c.44-5.97 1.27-11.9 2.48-17.76L195 185.4h27.02l-33.36 141.13H157.1l-22.62-93.47c-.65-2.69-1.4-6.2-2.23-10.53s-1.33-7.48-1.54-9.47h-.39c-.26 2.3-.77 5.71-1.54 10.23-.76 4.52-1.37 7.87-1.83 10.04l-21.27 93.17h-32.1L40.04 185.46h27.5l20.68 98.69c.48 2.02.92 4.72 1.34 8.06z"
      fill="#fff"
    />
  </svg>
)
