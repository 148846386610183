import { useState } from "react"
import { Loader } from "icons"
import { ShareUrlView } from "./ShareUrlView"
import {
  StyledContent,
  StyledH1,
  StyledAmountText,
  StyledRow,
  StyledDownloadButton,
  StyledShareButton,
} from "./Header.styles"

type Props = {
  readonly amount: number
  readonly isDownloading: boolean
  readonly isShared: boolean

  // Start downloading media bag
  onClickDownloadAll: () => void
}

export const ContentHeader = ({
  amount,
  isDownloading,
  isShared,
  onClickDownloadAll,
}: Props) => {
  const [showShare, setShowShare] = useState(false)

  const onClickShare = () => {
    setShowShare(true)
  }
  const onCloseShareModal = () => {
    setShowShare(false)
  }

  // Button inner content, supporting loader in button
  let buttonContent: JSX.Element | null = null
  if (isDownloading) {
    buttonContent = <Loader size="22" color="white" />
  } else {
    buttonContent = <>Download all</>
  }

  let pageTitle = "Media Bag"
  if (isShared) {
    pageTitle = "Shared Media Bag"
  }

  const isButtonDisabled = amount === 0

  return (
    <StyledContent>
      <StyledH1>
        {pageTitle}
        {amount > 0 && <StyledAmountText> ({amount})</StyledAmountText>}
      </StyledH1>
      <StyledRow>
        <StyledShareButton
          type="button"
          onClick={onClickShare}
          disabled={isButtonDisabled || isShared}>
          Share
        </StyledShareButton>
        <StyledDownloadButton
          type="button"
          onClick={onClickDownloadAll}
          disabled={isButtonDisabled}>
          {buttonContent}
        </StyledDownloadButton>
      </StyledRow>
      {showShare && <ShareUrlView close={onCloseShareModal} />}
    </StyledContent>
  )
}
