import { Route, Routes as R } from "react-router-dom"
import { Home } from "./home/Index"
import { Auth, ForgotPassword, Restore } from "./auth"
import { Search } from "./search"
import { Mediabag, MediabagRemote } from "./mediabag"
import { FolderList } from "./FolderList/FolderList"

export const Routes = () => (
  <R>
    <Route path="" element={<Home />} />
    <Route path="auth/login" element={<Auth />} />
    <Route path="auth/forgot-password" element={<ForgotPassword />} />
    <Route path="auth/restore/:id" element={<Restore />} />
    <Route path="mediabag" element={<Mediabag />} />
    <Route path="mediabag/:mediabagId" element={<MediabagRemote />} />
    <Route path="search/:elem" element={<Search />} />
    <Route path=":name/*" element={<FolderList />} />
  </R>
)
