import { StyledContent, StyledText } from "./FormResponse.styles"

type Props = {
  readonly hasError: boolean
  readonly text: string
}

export const FormResponse = ({ hasError = true, text }: Props) => (
  <StyledContent>
    {text && <StyledText data-error={hasError}>{text}</StyledText>}
  </StyledContent>
)
