import styled from "styled-components"

export const StyledLi = styled.li`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 0.5rem 0 0.5rem 0.75rem;
  transition: all 250ms ease-in;

  &:hover {
    background: #f3f4f6;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--border-color);
  }
`

export const StyledDropdownRow = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
`

export const StyledTitleText = styled.p`
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
`

export const StyledSubText = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding-top: 0.125rem;
`
