import { create } from "zustand"
import { createTrackedSelector } from "react-tracked"
import { ORDER } from "types/List"
import { State, StateValues } from "./State"
import { updateOrderBy } from "./updateOrderBy"
import { initLoad } from "./initLoad"
import { getDocumentsByFolderId } from "./getDocumentsByFolderId"

const INITIAL_VALUES: StateValues = {
  isFetchingMore: false,
  hasMore: true,
  documents: [],
}

export const useDocumentListStore = createTrackedSelector(
  create<State>((set, get) => ({
    orderBy: ORDER.LATEST, // Default value
    ...INITIAL_VALUES,

    /** Methods */

    initLoad: () => initLoad(set),

    // Get documnets by folder id
    getDocumentsByFolderId: (controller, folderUid) =>
      getDocumentsByFolderId(set, get, controller, folderUid),
    updateOrderBy: (value) => updateOrderBy(set, value),
    // Reset store to initial values
    reset: () => set({ ...INITIAL_VALUES }),
  })),
)
