import { StyledContent, StyledText } from "./Logo.styles"
import { Link } from "react-router-dom"

export const Logo = () => (
  <StyledContent>
    <Link to="/">
      <StyledText>Underwear Sweden</StyledText>
    </Link>
  </StyledContent>
)
