import styled, { css } from "styled-components"

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

export const StyledGrid = styled.div<{ $showPanel: boolean }>`
  display: grid;
  grid-template-columns: 1fr minmax(450px, 480px);
  gap: 1.5rem;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  ${({ $showPanel }) =>
    !$showPanel &&
    css`
      grid-template-columns: 1fr;
      gap: 0;
    `};
`

export const StyledCenter = styled.div`
  background-color: var(--color-grey-50);
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 10px;
`
