import styled, { css } from "styled-components"

export enum ICON_SIZE {
  DEFAULT,
  XSMALL,
  SMALL,
  LARGE,
  XLARGE,
}

export const StyledSvg = styled.svg<{ size: ICON_SIZE }>`
  display: block;
  height: var(--icon-md, 1.25rem);
  width: var(--icon-md, 1.25rem);

  ${({ size }) =>
    size === ICON_SIZE.XSMALL &&
    css`
      height: var(--icon-xs, 0.75rem);
      width: var(--icon-xs, 0.75rem);
    `};

  ${({ size }) =>
    size === ICON_SIZE.SMALL &&
    css`
      height: var(--icon-sm, 1rem;);
      width: var(--icon-sm, 1rem;);
    `};

  ${({ size }) =>
    size === ICON_SIZE.LARGE &&
    css`
      height: var(--icon-lg, 1.5rem);
      width: var(--icon-lg, 1.5rem);
    `};

  ${({ size }) =>
    size === ICON_SIZE.XLARGE &&
    css`
      height: var(--icon-xl, 2rem);
      width: var(--icon-xl, 2rem);
    `};
`
