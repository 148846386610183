import styled from "styled-components"

export const StyledOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.3);

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
`

export const StyledModalContent = styled.div`
  background-color: #fff;
  border-radius: 8px;
  cursor: initial;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: absolute;
  width: fit-content;
  left: 50%;
  top: 50%;
  height: auto;
  transform: translate(-50%, -50%);
  width: 548px;
`

export const StyledHeader = styled.div`
  display: flex;
  height: 28px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
`

export const StyledCloseBtn = styled.span`
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 32.526 32.526' style='enable-background:new 0 0 32.526 32.526;' xml:space='preserve'%3E%3Cpolygon points='32.526,2.828 29.698,0 16.263,13.435 2.828,0 0,2.828 13.435,16.263 0,29.698 2.828,32.526 16.263,19.091 29.698,32.526 32.526,29.698 19.091,16.263 '/%3E%3C/svg%3E%0A");
  background-size: 100% 100%;
  display: block;
  height: 14px;
  opacity: 0.4;
  transition: opacity 150ms ease-in;
  width: 14px;
`

export const StyledIconBox = styled.div`
  cursor: pointer;
  margin-left: auto;

  right: 20px;
  top: 20px;
  bottom: 0;
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 36px;
  width: 36px;

  background-color: #fff;
  border-radius: 50%;
  transition: background-color 150ms ease-in;

  &:hover {
    background-color: var(--color-grey-100);

    ${StyledCloseBtn} {
      opacity: 0.9;
    }
  }
`

export const StyledContent = styled.div`
  padding: 0 1.5rem 2rem;
`

export const StyledCopyBtn = styled.button`
  appearance: none;
  background-color: var(--company-color);
  border: 1px solid var(--company-color);
  border-radius: 6px;
  cursor: pointer;
  color: #fff;
  font-size: 0.8125rem;
  font-weight: 600;
  text-align: center;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  transition: all 300ms ease-in;
  width: 100%;

  :hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  :disabled {
    cursor: default;
  }
`

export const StyledInputBox = styled.input`
  appearance: none;
  background-color: var(--color-grey-100);
  border-radius: 6px;
  border: none;
  color: var(--color-grey-800);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    sans-serif;
  font-size: 0.8125rem;
  font-weight: 400;
  outline: none;
  height: 40px;
  padding: 0 12px;
  margin-bottom: 14px;
  transition: all 200ms ease-in;
  width: calc(100% - 24px);
`
