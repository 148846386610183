import { StyledContent, StyledItems } from "./ListMenu.styles"
import { SkeletonListItem } from "./SkeletonListItem"
import { HeaderSkeleton } from "./components/HeaderSkeleton"
type Props = {
  readonly itemsToShow: number
}

export const SkeletonListMenu = ({ itemsToShow }: Props) => {
  // Render skeleton items
  const items = Array.from(Array(itemsToShow).keys()).map((i) => (
    <SkeletonListItem key={i}></SkeletonListItem>
  ))

  return (
    <StyledContent>
      <HeaderSkeleton />
      <StyledItems>{items}</StyledItems>
    </StyledContent>
  )
}
