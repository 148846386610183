import styled from "styled-components"

export const StyledFooter = styled.footer`
  border-top: 1px solid var(--border-color);
  display: block;
  position: relative;
  margin-top: 1rem;

  @media (min-width: 768px) and (max-width: 1279px) {
    margin-top: 2.5rem;
  }

  @media (min-width: 1280px) {
    margin-top: 4rem;
  }
`

export const StyledFooterInner = styled.div`
  background: var(--footer-background-color);
  display: flex;
  flex-direction: column;

  margin: 0px auto;
  padding: 3rem 2rem;

  gap: 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
    max-width: 1220px;
    width: calc(100% - 4rem);
  }
`
