import { useEffect } from "react"
import { EyeClosedIcon, EyeIcon } from "icons"
import {
  StyledFigure,
  StyledCloseIcon,
  FullScreenLayer,
  StyledHeader,
  StyledRow,
  StyledHidePanel,
  StyledHidePanelBox,
  StyledHidePanelText,
  StyledContent,
  StyledIconBox,
  StyledTooltip,
} from "./Overlay.styles"
import { DownloadIcon } from "icons"

type Props = {
  children?: React.ReactNode
  readonly showPanel: boolean

  /** Methods */

  close: () => void
  onClickHidePanel: () => void
  // User clicked on download original file
  onClickDownload: () => void
}

export const Overlay = ({
  showPanel,
  children,
  close,
  onClickHidePanel,
  onClickDownload,
}: Props) => {
  useEffect(() => {
    // When user clicks back button in the browser we close the overlay
    const handleEvent = () => close()
    window.addEventListener("popstate", handleEvent)
    return () => window.removeEventListener("popstate", handleEvent)
  }, [])

  let panelText = "Hide panel"
  let eyeIcon = <EyeClosedIcon />

  if (!showPanel) {
    panelText = "Show panel"
    eyeIcon = <EyeIcon />
  }

  return (
    <FullScreenLayer>
      <StyledHeader>
        <StyledIconBox onClick={onClickDownload}>
          <DownloadIcon />
          <StyledTooltip>Download original file</StyledTooltip>
        </StyledIconBox>
        <StyledRow>
          <StyledHidePanel onClick={onClickHidePanel}>
            <StyledHidePanelBox>
              {eyeIcon}
              <StyledHidePanelText>{panelText}</StyledHidePanelText>
            </StyledHidePanelBox>
          </StyledHidePanel>
          <StyledFigure onClick={close}>
            <StyledCloseIcon />
          </StyledFigure>
        </StyledRow>
      </StyledHeader>
      <StyledContent>{children}</StyledContent>
    </FullScreenLayer>
  )
}
