import { useState, useEffect, FormEvent, ReactNode, useRef } from "react"
import { useParams } from "react-router-dom"
import { Header, Input, FormResponse, FormButton, FormLink } from "./atoms"
import { DotCircleLoader } from "jsx/atoms"
import {
  StyledContent,
  StyledBox,
  StyledForm,
  StyledInputBox,
} from "./Auth.styles"
import { StyledLoaderContent, StyledInvalidToken } from "./Restore.styles"

export const Restore = () => {
  const { id } = useParams()
  const passwordRef = useRef<HTMLInputElement>(null)
  const passwordRepeatRef = useRef<HTMLInputElement>(null)

  const [isCheckingToken, setIsCheckingToken] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [successText, setSuccessText] = useState("")
  const [errorText, setErrorText] = useState("")

  useEffect(() => {
    const controller = new AbortController()

    // Validate token before showing input form
    const validateToken = async () => {
      setIsCheckingToken(true)

      const url = `${process.env.REACT_APP_API_ACCOUNT}/v0/auth/restore/${id}`
      const options = {
        headers: {
          application: process.env.REACT_APP_APP_UID || "",
          customer: process.env.REACT_APP_CUSTOMER_UID || "",
        },
        signal: controller.signal,
      }
      const resp = await fetch(url, options)
      if (resp.status === 204) {
        setIsValid(true)
      }

      setIsCheckingToken(false)
    }

    validateToken()
    return () => controller.abort()
  }, [id])

  const onSubmitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsUpdating(true)

    if (
      !passwordRef.current ||
      !passwordRepeatRef.current ||
      passwordRef.current?.value !== passwordRepeatRef.current?.value
    ) {
      setErrorText("Passwords do not match.")
      setIsUpdating(false)
      return
    }

    // Clear old error message
    if (errorText) setErrorText("")

    const url = `${process.env.REACT_APP_API_ACCOUNT}/v0/auth/restore/${id}`
    const options = {
      method: "POST",
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "Content-Type": "application/json",
        application: process.env.REACT_APP_APP_UID || "",
        customer: process.env.REACT_APP_CUSTOMER_UID || "",
      },
      body: JSON.stringify({
        password: passwordRef.current?.value || "",
        password_repeat: passwordRepeatRef.current?.value || "",
      }),
    }
    const resp = await fetch(url, options)

    if (resp.status === 204) {
      setSuccessText("Your password has been updated.")

      // Clear input fields
      passwordRef.current.value = ""
      passwordRepeatRef.current.value = ""
    } else {
      const body = (await resp?.json()) || undefined
      setErrorText(
        body?.description ||
          "Failed to update your password. Please try again soon.",
      )
    }

    setIsUpdating(false)
  }

  let innerContent: ReactNode = null
  if (isCheckingToken) {
    innerContent = (
      <StyledLoaderContent>
        <DotCircleLoader />
      </StyledLoaderContent>
    )
  } else if (isValid) {
    innerContent = (
      <div>
        <Header title="Update your password" />
        <StyledInputBox>
          <Input
            type="password"
            label="Password"
            name="password"
            ref={passwordRef}
            autofocus={false}
          />
        </StyledInputBox>
        <StyledInputBox>
          <Input
            type="password"
            label="Repeat Password"
            name="password"
            ref={passwordRepeatRef}
            autofocus={false}
          />
        </StyledInputBox>
        <FormButton isLoading={isUpdating} text="Update password" />
      </div>
    )
  } else if (!isValid) {
    innerContent = (
      <div>
        <Header title="Invalid token" />
        <StyledInvalidToken>
          <p>
            If you have already updated your password or want to request a new
            token, press the button below.
          </p>
        </StyledInvalidToken>
      </div>
    )
  }

  return (
    <StyledContent>
      <StyledBox>
        <StyledForm onSubmit={onSubmitForm}>
          {innerContent}
          <FormResponse
            text={successText || errorText}
            hasError={errorText !== ""}
          />
          <FormLink url="/auth/login" text="Go back to login" />
        </StyledForm>
      </StyledBox>
    </StyledContent>
  )
}
