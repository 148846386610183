import styled from "styled-components"
import { ExcelIcon, WordIcon, PowerpointIcon } from "jsx/atoms/icons"

export const StyledWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  height: 100%;
  width: 100%;

  figure {
    display: flex;
    justify-content: center;
  }
`

export const StyledEmbed = styled.embed`
  height: 100%;
  width: 100%;
`

export const StyledIframe = styled.iframe`
  height: 100%;
  width: 100%;
`

export const StyledExcelIcon = styled(ExcelIcon)`
  height: 68px;
  width: 68px;
`

export const StyledPowerpointIcon = styled(PowerpointIcon)`
  height: 68px;
  width: 68px;
`

export const StyledWordIcon = styled(WordIcon)`
  height: 68px;
  width: 68px;
`
