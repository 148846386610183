import { StyledItem, StyledText } from "./TagItem.styles"

type Props = {
  readonly name: string
}

export const TagItem = ({ name }: Props) => (
  <StyledItem>
    <StyledText>{name}</StyledText>
  </StyledItem>
)
