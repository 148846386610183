import { StyledMenu } from "./Menu.styles"
import { Treeview } from "types"
import { MenuItem } from "./MenuItem"

type Props = {
  readonly treeview: Treeview
}

export const Menu = ({ treeview }: Props) => {
  if (!treeview) {
    return null
  }

  // Get root folders
  const rootFolders = treeview.nodes?.map((f) => {
    const url = `/${encodeURIComponent(f.name.toLowerCase())}`
    return <MenuItem key={f.id} url={url} treeview={f} />
  })

  return <StyledMenu>{rootFolders}</StyledMenu>
}
