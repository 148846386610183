import { useState } from "react"
import { ListPreviewImage } from "types"
import {
  StyledCenter,
  StyledPDFBox,
  StyledEPSBox,
  StyledIconText,
  StyledExcelIcon,
  StyledPowerpointIcon,
  StyledWordIcon,
} from "./DocumentElement.styles"
import { MIME_TYPE } from "@invenseit/ts-sdk"
import { StyledPicture } from "../ImageElement/ImageElement.styles"

type Props = {
  readonly mimeType: string
  readonly preview: ListPreviewImage[] | null
}

export const DocumentElement = ({ mimeType, preview }: Props) => {
  const [isLoaded, setIsLoaded] = useState(false)

  const imageLoaded = () => {
    setIsLoaded(true)
  }

  // Microsoft Excel preview
  if (mimeType === MIME_TYPE.EXCEL || mimeType === MIME_TYPE.EXCELSPREEDSHEET) {
    return (
      <StyledCenter>
        <StyledExcelIcon />
      </StyledCenter>
    )
  }

  // Microsoft Powerpoint preview
  if (
    mimeType === MIME_TYPE.POWERPOINT ||
    mimeType === MIME_TYPE.POWERPOINTPRESENTATION
  ) {
    return (
      <StyledCenter>
        <StyledPowerpointIcon />
      </StyledCenter>
    )
  }

  // Microsof word preview
  if (mimeType === MIME_TYPE.WORD || mimeType === MIME_TYPE.WORDDOCUMENT) {
    return (
      <StyledCenter>
        <StyledWordIcon />
      </StyledCenter>
    )
  }

  // If preview exist show else fallback to SVG
  if (!preview) {
    if (mimeType === "application/postscript") {
      return (
        <StyledCenter>
          <StyledEPSBox>
            <StyledIconText>EPS</StyledIconText>
          </StyledEPSBox>
        </StyledCenter>
      )
    }

    // Fallback to PDF icon
    return (
      <StyledCenter>
        <StyledPDFBox>
          <StyledIconText>PDF</StyledIconText>
        </StyledPDFBox>
      </StyledCenter>
    )
  }

  const imageUrl =
    preview[3]?.["jpeg"] || preview[2]?.["jpeg"] || preview[1]?.["jpeg"] || ""

  return (
    <StyledCenter>
      <StyledPicture data-loaded={isLoaded}>
        <img
          decoding="async"
          src={imageUrl}
          alt=""
          loading="lazy"
          onLoad={imageLoaded}
        />
      </StyledPicture>
    </StyledCenter>
  )
}
