import styled from "styled-components"

export const StyledLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const StyledLi = styled.li`
  &:not(:last-child) {
    padding-bottom: 12px;
  }
`

export const StyledText = styled.p`
  color: var(--footer-text-color);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;

  @media (max-width: 768px) {
    font-size: 0.8125rem;
  }
`
