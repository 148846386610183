/* eslint-disable jsx-a11y/media-has-caption */
import type { File } from "types"
import styled from "styled-components"

const StyledVideo = styled.video`
  max-height: calc(100vh - 92px);
  width: 100%;
`

type Props = {
  readonly file: File
}

export const Movie = ({ file }: Props) => {
  // TODO:
  if (!file.metadata?.url) {
    // TODO: Show preview video image
    return null
  }

  return (
    <StyledVideo controls>
      <source src={file.metadata.url} type={file.mime_type} />
    </StyledVideo>
  )
}
