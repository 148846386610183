import styled from "styled-components"

export const StyledLabel = styled.label`
  color: var(--header-text-color);
  display: block;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.025em;
  margin: 0 0 6px 4px;
`

export const StyledInput = styled.input`
  appearance: none;
  border: 1px solid var(--border-color);
  border-radius: 2px;
  color: var(--text-body-color);
  height: 42px;
  font-size: 13px;
  font-weight: 400;
  outline: none;
  transition: border 200ms ease-in;
  padding: 0 0.875rem;
  width: calc(100% - 24px);

  &:focus {
    border: 1px solid var(--border-input-hover);
  }
  &:hover {
    border: 1px solid var(--border-input-hover);
  }

  &[type="password"] {
    font: caption;
    font-size: 16px;

    letter-spacing: 0.025rem;
  }
`
