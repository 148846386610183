import { create } from "zustand"
import { createTrackedSelector } from "react-tracked"
import { State, StateValues } from "./State"
import { managePanel } from "./panel"

const INITIAL_VALUES: StateValues = {
  showPanel: true,
}

export const useSingleViewStore = createTrackedSelector(
  create<State>((set, get) => ({
    ...INITIAL_VALUES,

    // Show or hide panel
    managePanel: () => managePanel(set, get),
    // Reset store
    reset: () => set({ ...INITIAL_VALUES }),
  })),
)
