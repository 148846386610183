import styled from "styled-components"

export const StyledPicture = styled.picture`
  align-items: center;
  display: flex;
  height: inherit;

  img {
    display: block;
    outline: none;

    height: 100%;
    object-fit: contain;
    max-height: 300px;
    width: 100%;
  }
`
