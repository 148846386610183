import styled from "styled-components"

const StyledHeader = styled.header`
  display: block;
  width: 100%;
`

const StyledTitleText = styled.h1`
  font-size: 2.125rem;
  text-align: left;
  font-weight: 700;
`

type Props = {
  readonly text: string
}

export const SectionTitle = ({ text }: Props) => (
  <StyledHeader>
    <StyledTitleText>{text}</StyledTitleText>
  </StyledHeader>
)
