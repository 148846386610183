import styled from "styled-components"

export const StyledHeader = styled.header`
  align-items: center;
  display: flex;

  min-height: 30px;
  margin-bottom: 16px;
  width: 100%;

  justify-content: space-between;

  @media (min-width: 768px) and (max-width: 1279px) {
    margin-bottom: 1rem;
  }
  @media (min-width: 1280px) and (max-width: 1439px) {
    margin-bottom: 26px;
  }
  @media (min-width: 1440px) {
    margin-bottom: 30px;
  }
`

export const StyledTitle = styled.h1`
  font-size: 1.75rem;
  font-weight: 700;
`
