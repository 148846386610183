import styled from "styled-components"

export const StyledContent = styled.div`
  display: none;

  cursor: pointer;
  grid-area: logo;
  user-select: none;

  @media (min-width: 768px) {
    align-items: center;
    display: flex;
  }
`

export const StyledText = styled.p`
  color: var(--color-grey-800);
  font-size: 1rem;
  font-weight: 700;

  letter-spacing: 0.2px;
  text-transform: uppercase;
`
