import { useRef, useState } from "react"
import { Dropdown } from "jsx/atoms"
import { ChrevronUpOutline } from "icons"
import { useElementSize } from "hooks"
import { bytesToSize } from "functions/size"
import {
  StyledField,
  StyledLabel,
  StyledInput,
  StyledUl,
  StyledArrowPosition,
  StyledInputContainer,
} from "./DropdownMenu.styles"
import { DropdownItem } from "./DropdownItem"

type DropdownMenuProps = {
  readonly value: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly options: any // TODO:

  /** Methods */
  onChange: (id: string) => void
}

export const DropdownMenu = ({
  value,
  options,
  onChange,
}: DropdownMenuProps) => {
  const inputSizeRef = useRef(null)
  const { width } = useElementSize(inputSizeRef)
  const [isShowingModel, setIsShowingModel] = useState(false)

  // Open or close modal
  const onClickShowModal = () => setIsShowingModel(true)
  const onClickHideModal = () => setIsShowingModel(false)

  const onChangeResolution = (resolutionId: string) => {
    onClickHideModal()
    onChange(resolutionId)
  }

  const listItems = options.map((item) => {
    let sizeFormat = ""

    if (item.height && item.width) {
      sizeFormat = `${item.width} x ${item.height} px`
    }

    if (item.size) {
      if (sizeFormat !== "") {
        sizeFormat += " | "
      }
      sizeFormat += bytesToSize(item.size)
    }

    return (
      <DropdownItem
        key={item.id}
        name={item.name}
        subText={sizeFormat}
        onClick={() => onChangeResolution(item.id)}
      />
    )
  })

  // find selected resoultion
  let labelText = value
  if (value !== "Original") {
    const optLabel = options.find((obj) => obj.id === value)
    if (optLabel) {
      labelText = optLabel.name
    }
  }

  return (
    <StyledField>
      <StyledLabel>Select image size</StyledLabel>
      <StyledInputContainer onClick={onClickShowModal}>
        <StyledInput
          ref={inputSizeRef}
          type="button"
          name="select-image-size"
          value={labelText}
          data-active={isShowingModel}
        />
        {listItems.length > 0 && (
          <StyledArrowPosition deg={isShowingModel ? 180 : 0}>
            <ChrevronUpOutline />
          </StyledArrowPosition>
        )}
      </StyledInputContainer>
      {isShowingModel && (
        <Dropdown hide={onClickHideModal}>
          <StyledUl width={width}>{listItems}</StyledUl>
        </Dropdown>
      )}
    </StyledField>
  )
}
