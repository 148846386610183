export function findPath(a, obj) {
  function iter(o, p) {
    return Object.keys(o).some((k) => {
      result = p.concat(Array.isArray(o) ? +k : k)
      return (
        (k !== "parent_id" && o[k] === a) ||
        (o[k] && typeof o[k] === "object" && iter(o[k], result))
      )
    })
  }
  var result
  return (iter(obj, []) && result) || undefined
}
