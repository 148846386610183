import { useState } from "react"
import { File, FileTag, PreGeneratedImageResolutions } from "types"
import { bytesToSize } from "functions/size"
import { useTreeviewStore, useMediabagStore } from "stores"
import get from "lodash/get"
import orderBy from "lodash/orderBy"
import { cErr, getImageDimensions, findPath } from "utils"
import {
  StyledContent,
  StyledSection,
  StyledNameTitle,
  StyledUl,
  StyledLi,
  StyledLiText,
  StyledSpan,
  StyledAddToBasketBtn,
  StyledDownloadBtn,
  StyledLocationPath,
  StyledActionButtons,
  StyledCheckMark,
} from "./SideMenu.styles"
import { Loader } from "icons"
import { DropdownMenu } from "./components/DropdownMenu"
import { DEFAULT_RESOLUTION } from "../../constants"
import { downloadFileHandler } from "../../utils"
import { Tags } from "./molecules"
import { Header } from "./atoms"

const listDateFormat = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "short",
  day: "2-digit",
})

type Props = {
  readonly file: File
  readonly fileTags: FileTag[]
}

export const SideMenu = ({ file, fileTags }: Props) => {
  const [isDownloading, setIsDownloading] = useState(false)
  const [isAddedToBag, setIsAddedToBag] = useState(false)
  const [selectedRes, setSelectedRes] = useState<string>(DEFAULT_RESOLUTION)
  const mediabagstore = useMediabagStore()
  const { treeview } = useTreeviewStore()

  const onClickAddToBasket = async () => {
    // Get resolution id if user has chosen one
    const resolutionId = selectedRes !== DEFAULT_RESOLUTION ? selectedRes : null

    mediabagstore.add(file.id, resolutionId)
    setIsAddedToBag(true)
    // Wait 2 seconds
    await new Promise((resolve) => setTimeout(resolve, 2000))
    setIsAddedToBag(false)
  }

  // Download document or image
  const onClickDowload = async () => {
    setIsDownloading(true)
    try {
      const name = `${file.filename}.${file.file_extension}`
      await downloadFileHandler(file, name, selectedRes)
    } catch (err) {
      cErr("unable to download file", err)
      // TODO: show error message to user
    } finally {
      setIsDownloading(false)
    }
  }

  const onChangeImageResolution = (resolutionUid: string) =>
    setSelectedRes(resolutionUid)

  const fileSize = bytesToSize(file.size)

  // Get file location from folder uid
  let locationPath = ""
  const paths = findPath(file.folder.id, treeview)

  if (paths && paths[paths.length - 1] === "id") {
    paths.pop()

    for (let i = 0; i < paths.length; i += 1) {
      if (paths[i] !== "nodes") {
        const p = get(treeview, paths.slice(0, i + 1).join("."))
        if (p.name) {
          if (locationPath.length < 1) {
            locationPath += p.name
          } else {
            locationPath += ` / ${p.name}`
          }
        }
      }
    }
  }

  let createdDate = ""
  if (file.created_at) {
    createdDate = listDateFormat.format(new Date(file.created_at)) || ""
  }
  let modifiedDate = ""
  if (file.updated_at) {
    modifiedDate = listDateFormat.format(new Date(file.updated_at)) || ""
  }

  const availableSizesOrdered = orderBy(file.resolutions, ["name"], ["asc"])
  availableSizesOrdered.unshift({
    id: "original",
    name: "Original",
    height: file.metadata?.height || 0,
    width: file.metadata?.width || 0,
    size: file.size,
    image_size: null,
  } as PreGeneratedImageResolutions)

  // Button inner content
  let downloadButtonContent: JSX.Element | null = null
  if (isDownloading) {
    downloadButtonContent = <Loader size="24" color="white" />
  } else {
    downloadButtonContent = <>Download</>
  }

  const bagButtonContent = isAddedToBag ? <StyledCheckMark /> : <>Add to bag</>

  // Width, height and DPI
  const imageDimensions = getImageDimensions(file.metadata)

  // Setup filename, check if filename already have extension (.jpg, .png, etc)
  let filename = ""
  const filenameSplitted = file.filename.split(".")
  if (filenameSplitted.length >= 2) {
    filename = file.filename
  } else {
    filename = `${file.filename}.${file.file_extension}`
  }

  return (
    <StyledContent>
      <StyledSection>
        <StyledNameTitle>{filename}</StyledNameTitle>
      </StyledSection>
      <StyledSection>
        <Header title="File location" />
        <StyledLocationPath>{locationPath}</StyledLocationPath>
      </StyledSection>
      <StyledSection>
        <Header title="Details" />
        <StyledUl>
          {imageDimensions && (
            <StyledLi>
              <StyledLiText>
                Dimensions: <StyledSpan>{imageDimensions}</StyledSpan>
              </StyledLiText>
            </StyledLi>
          )}
          <StyledLi>
            <StyledLiText>
              File size: <StyledSpan>{fileSize}</StyledSpan>
            </StyledLiText>
          </StyledLi>
          <StyledLi>
            <StyledLiText>
              File format:{" "}
              <StyledSpan>
                {file.file_extension?.toUpperCase() || ""}
              </StyledSpan>
            </StyledLiText>
          </StyledLi>
          <StyledLi>
            <StyledLiText>
              Uploaded: <StyledSpan>{createdDate}</StyledSpan>
            </StyledLiText>
          </StyledLi>
          <StyledLi>
            <StyledLiText>
              Modified: <StyledSpan>{modifiedDate}</StyledSpan>
            </StyledLiText>
          </StyledLi>
        </StyledUl>
      </StyledSection>
      <StyledSection>
        <Tags tags={fileTags} />
      </StyledSection>
      <StyledSection>
        {availableSizesOrdered.length > 1 && (
          <DropdownMenu
            value={selectedRes}
            options={availableSizesOrdered}
            onChange={onChangeImageResolution}
          />
        )}
        <StyledActionButtons>
          <StyledAddToBasketBtn
            type="button"
            onClick={onClickAddToBasket}
            data-completed={isAddedToBag}>
            {bagButtonContent}
          </StyledAddToBasketBtn>
          <StyledDownloadBtn type="button" onClick={onClickDowload}>
            {downloadButtonContent}
          </StyledDownloadBtn>
        </StyledActionButtons>
      </StyledSection>
    </StyledContent>
  )
}
