import Cookies from "js-cookie"
import { ORDER } from "types/List"

export const COOKIE_PRODUCT_LIST_CONFIG = "list_config"

export interface ListConfig {
  orderBy: string
}

export const setListConfigCookie = (data: ListConfig) => {
  // Expire after 3 days
  Cookies.set(COOKIE_PRODUCT_LIST_CONFIG, JSON.stringify(data), { expires: 3 })
}

export const getListConfig = (): ListConfig => {
  const config = Cookies.get(COOKIE_PRODUCT_LIST_CONFIG)
  if (config !== undefined) {
    return JSON.parse(config)
  }
  // Create default config
  const d: ListConfig = {
    orderBy: ORDER.LATEST,
  }
  setListConfigCookie(d)
  return d
}
