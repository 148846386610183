import styled from "styled-components"

export const StyledContent = styled.div`
  display: block;
  padding-top: 0.75rem;
`

export const StyledText = styled.p`
  color: var(--auth-form-success-text-color, #15803d);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  margin-top: 8px;
  padding: 0;
  text-align: center;

  &[data-error="true"] {
    color: var(--auth-form-error-text-color, #fb525a);
  }
`
