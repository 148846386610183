import { createRoot, hydrateRoot } from "react-dom/client"
import { App } from "./jsx/app"
import { unregister } from "./registerServiceWorker"

const rootElement = document.getElementById("root")
if (!rootElement) {
  throw new Error("Failed to find the root element")
}

if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement, <App />)
} else {
  const root = createRoot(rootElement)
  root.render(<App />)
}

unregister()
