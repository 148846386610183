import type { Treeview } from "types/Treeview"

// Find folder id by location paths
export const findFolderByPath = (
  path: string,
  currentNode: Treeview,
): Treeview | null => {
  if (
    !currentNode ||
    (Object.keys(currentNode).length === 0 &&
      currentNode.constructor === Object)
  ) {
    return null
  }

  if (path === currentNode.name.toLowerCase()) {
    return currentNode
  }

  if (currentNode.nodes && Array.isArray(currentNode.nodes)) {
    // Use a for loop instead of forEach to avoid nested functions
    // Otherwise "return" will not work properly
    for (let i = 0; i < currentNode.nodes.length; i += 1) {
      const currentChild = currentNode.nodes[i]
      // Search in the current child
      const result = findFolderByPath(path, currentChild)
      // Return the result if the node has been found
      if (result !== null) {
        return result
      }
    }
  }

  return null
}

export const findFolderById = (
  folderId: string,
  currentNode: Treeview,
): Treeview | null => {
  if (
    !currentNode ||
    (Object.keys(currentNode).length === 0 &&
      currentNode.constructor === Object)
  ) {
    return null
  }

  if (folderId === currentNode.id) {
    return currentNode
  }

  if (currentNode.nodes && Array.isArray(currentNode.nodes)) {
    // Use a for loop instead of forEach to avoid nested functions
    // Otherwise "return" will not work properly
    for (let i = 0; i < currentNode.nodes.length; i += 1) {
      const currentChild = currentNode.nodes[i]
      // Search in the current child
      const result = findFolderById(folderId, currentChild)
      // Return the result if the node has been found
      if (result !== null) {
        return result
      }
    }
  }

  // The node has not been found and we have no more options
  return null
}
