import { MediabagItem } from "types/Mediabag"

// Expires in days
export const COOKIE_EXPIRE = 2
export const MEDIABAG_COOKIE = "mediabag"

export type State = {
  amount: number
  items: MediabagItem[]

  /** Methods */

  add: (fileId: string, resolutionId: string | null) => Promise<void>
  // Load bag if it exist in cookie
  load: () => void
  clear: () => void
  remove: (fileId: string) => void
}
