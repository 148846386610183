// bytesToSize - Convert bytes to SI unit
export const bytesToSize = (bytes: number | null) => {
  if (!bytes) {
    return "0 Byte"
  }

  const sizes = ["Bytes", "KB", "MB", "GB", "TB"]

  const i = Math.floor(Math.log(bytes) / Math.log(1024))

  return `${Math.round(bytes / 1024 ** i)} ${sizes[i]}`
}
