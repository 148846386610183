import styled from "styled-components"
import { fadeIn } from "jsx/styles/Keyframes"

export const StyledPicture = styled.picture`
  outline: none;

  max-width: 100%;
  max-height: 100%;
  height: auto;
  pointer-events: none;

  opacity: 0;
  animation: ${fadeIn} 1s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
`

export const StyledImg = styled.img`
  display: block;
  height: auto;
  outline: none;

  max-width: 100%;
  max-height: calc(100vh - 126px);
  width: auto;
`
