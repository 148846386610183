import { useState, useEffect } from "react"
import { useDocumentListStore } from "stores"
import type { ORDER } from "types/List"
import { Header } from "./components/Header"
import {
  StyledContent,
  StyledItems,
  StyledScrollUpCircle,
  StyledScrollButton,
  StyledChrevronUp,
} from "./ListMenu.styles"

type Props = {
  readonly showingItem: boolean
  readonly folderUid: string | undefined
  children: React.ReactNode

  /** Methods */

  handleOrderBy: (orderBy: ORDER) => void
}

export const ListMenu = ({
  showingItem,
  folderUid,
  children,
  handleOrderBy,
}: Props) => {
  const { orderBy } = useDocumentListStore()
  const [showTopBtn, setShowTopBtn] = useState(false)

  const handleScrollUp = () => {
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" })
  }

  useEffect(() => {
    const handleScroll = () => {
      setShowTopBtn(window.pageYOffset > 50)
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleOrderByChange = (value: ORDER) => {
    handleOrderBy(value)
  }

  return (
    <StyledContent>
      <Header
        folderUid={folderUid}
        orderBy={orderBy}
        hideOrder={false}
        changeOrderBy={handleOrderByChange}
      />
      <StyledItems>{children}</StyledItems>
      <StyledScrollUpCircle data-visible={showTopBtn && !showingItem}>
        <StyledScrollButton
          type="button"
          onClick={handleScrollUp}
          data-visible={showTopBtn && !showingItem}>
          <StyledChrevronUp />
        </StyledScrollButton>
      </StyledScrollUpCircle>
    </StyledContent>
  )
}
