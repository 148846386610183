import styled from "styled-components"

export const StyledContent = styled.div`
  width: 100%;
`

export const PageContent = styled.div`
  display: block;
  margin-top: 16px;
  width: 100%;
`
