import styled from "styled-components"

export const StyledCenter = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`

export const StyledIcon = styled.div`
  border-radius: 4px;
  height: 25%;
  position: relative;
  width: 20%;
  min-width: 42px;
`

export const StyledMovieBox = styled(StyledIcon)`
  border: 1px solid #fde9c8;
  background-color: #fde9c8;
  background-repeat: no-repeat;
  background-size: 38%;
  background-position: 20% 12%;
`

export const StyledIconText = styled.p`
  background: #9ca3af;
  border-radius: 4px;
  bottom: 0;
  color: #fff;

  font-size: 12px;
  font-weight: 700;

  left: -5px;

  margin-bottom: 13%;
  padding: 1px 5px;
  position: absolute;
  text-transform: uppercase;
`
