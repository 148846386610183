import styled from "styled-components"

export const StyledBox = styled.div`
  justify-content: flex-end;
  position: relative;
`

export const StyledFilterBox = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  user-select: none;

  svg {
    display: block;
    margin-left: 8px;
    height: 20px;
    width: 20px;
    transition: transform 200ms linear;

    &[data-active="true"] {
      transform: rotate(180deg);
    }
  }

  &[data-active="true"] {
    pointer-events: none;
  }
`

export const StyledFilterLabel = styled.p`
  color: var(--header-text-color);
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 16px;

  span {
    font-weight: 600;
  }
`
