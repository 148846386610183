import { useState } from "react"
import { AxiosResponse } from "axios"
import { ContentHeader } from "./components/Header"
import { StyledContent, PageContent } from "./MediabagTemplate.styles"
import { MediaBagEmpty } from "./components/EmptyMediabag"
import { GenerateSignedUrlResp, File } from "types"
import { useMediabagStore, useAuthStore } from "stores"
import { cErr, downloadFile } from "utils"
import { RowItem } from "./components/RowItem"
import { damAPI } from "utils/DamAPI"

type Props = {
  // If the bag is shared or not
  readonly isShared: boolean
  readonly items: File[]
}

const collator = new Intl.Collator("en", {
  sensitivity: "base",
  numeric: true,
  usage: "sort",
})

export const MediabagTemplate = ({ isShared, items }: Props) => {
  const authStore = useAuthStore()
  const mediabagStore = useMediabagStore()
  const [isDownloading, setIsDownloading] = useState(false)

  // Remove item from mediabag
  const onClickRemove = (fileId: string) => mediabagStore.remove(fileId)

  // User click on download single file
  const onClickDownloadSingle = async (fileId: string) => {
    // Find file in items
    const file = items.find((item) => item.id === fileId)
    if (!file) {
      cErr("File not found in items")
      return
    }
    // Find item in mediabag
    const mediabagItem = mediabagStore.items.find(
      (item) => item.fileId === fileId,
    )
    if (!mediabagItem) {
      cErr("File not found in mediabag")
      return
    }

    let filename = ""
    let downloadUrl: string | undefined
    let generateUrl = `/public/file/${mediabagItem.fileId}/download/signed-url`

    // Check if resolution id exist
    if (mediabagItem.resolutionId !== null) {
      generateUrl += `?resolution_id=${mediabagItem.resolutionId}`
    }

    // Generate a signed download url
    try {
      const resp: AxiosResponse<GenerateSignedUrlResp> = await damAPI.get(
        generateUrl,
      )
      downloadUrl = resp.data?.url || ""
      filename = resp.data?.filename || `${file.filename}${file.file_extension}`
    } catch (err) {
      cErr("failed to generate a signed url", err)
      return
    }

    if (!downloadUrl) {
      // TODO: show error message
      cErr("invalid download url")
      return
    }

    // Download file from cloud storage
    try {
      await downloadFile(downloadUrl, filename)
    } catch (err) {
      // TODO: show error to user
      cErr("failed to download file from storage", err)
    }
  }

  const onClickDownloadAll = async () => {
    setIsDownloading(true)

    await Promise.all(
      items.map(async (item) => {
        let filename = ""
        let downloadUrl: string | undefined

        /*  Generate a signed download url */
        let generateUrl = `/public/file/${item.id}/download/signed-url`

        // Find item in mediabag
        const mediabagItem = mediabagStore.items.find(
          (bagItem) => bagItem.fileId === item.id,
        )
        if (!mediabagItem) {
          cErr("File not found in mediabag")
          return
        }
        // Check if resolution id exist
        if (mediabagItem.resolutionId !== null) {
          generateUrl += `?resolution_id=${mediabagItem.resolutionId}`
        }

        try {
          const resp: AxiosResponse<GenerateSignedUrlResp> = await damAPI.get(
            generateUrl,
          )
          downloadUrl = resp.data?.url || ""
          filename =
            resp.data?.filename || `${item.filename}${item.file_extension}`
        } catch (err) {
          cErr("failed to generate a signed url", err)
          return
        }

        if (!downloadUrl) {
          // TODO: show error message
          cErr("invalid download url")
          return
        }

        // Download file from cloud storage
        try {
          await downloadFile(downloadUrl, filename)
        } catch (err) {
          // TODO: show error to user
          cErr("failed to download file from storage", err)
        }
      }),
    )

    setIsDownloading(false)
  }

  let content: JSX.Element | null = null
  if (Object.keys(items).length === 0) {
    content = <MediaBagEmpty />
  } else if (items.length > 0) {
    // Sort items by name ASC
    const itemsOrdered = items
      .slice()
      .sort((a, b) => collator.compare(a.filename, b.filename))

    const mediabagItems = itemsOrdered.map((item) => {
      // check if another resolution is selected
      const resolutionId = mediabagStore.items.find(
        (mediabagItem) => mediabagItem.fileId === item.id,
      )?.resolutionId

      return (
        <RowItem
          key={item.id}
          isRemote={isShared}
          item={item}
          resolutionId={resolutionId || null}
          onClickDownload={() => onClickDownloadSingle(item.id)}
          onClickRemove={() => onClickRemove(item.id)}
          token={authStore.token || undefined}
        />
      )
    })
    content = <PageContent>{mediabagItems}</PageContent>
  }

  return (
    <StyledContent>
      <ContentHeader
        isShared={isShared}
        amount={items.length}
        isDownloading={isDownloading}
        onClickDownloadAll={onClickDownloadAll}
      />
      {content}
    </StyledContent>
  )
}
