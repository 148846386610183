import { Dispatch, SetStateAction } from "react"
import { useSearchParams } from "react-router-dom"
import { ListMenuItem } from "../listMenu"
import debounce from "lodash/debounce"
import { HandleScroll } from "../listMenu/HandleScroll"
import { ListMenu } from "../listMenu"
import { SingleView } from "../singleView"
import type { ORDER } from "types/List"
import type { ListFileItem } from "types"

type Props = {
  showItem: string | undefined
  folderId: string | undefined
  data: ListFileItem[]

  /** Methods */

  loadMore: () => void
  handleOrderBy: (orderBy: ORDER) => void
  setShowItem: Dispatch<SetStateAction<string | undefined>>
}

export const Template = ({
  showItem,
  folderId,
  data,
  loadMore,
  handleOrderBy,
  setShowItem,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const loadMoreDebounced = debounce(loadMore, 500, { leading: false })

  const onClickItem = (id: string) => {
    setShowItem(id)
    setSearchParams({ id: id })
  }

  const closeItemView = () => {
    setShowItem(undefined)
    // Delete id param from url
    searchParams.delete("id")
    setSearchParams(searchParams)
  }

  const items = data.map((p) => (
    <ListMenuItem key={p.id} item={p} onClickView={onClickItem} />
  ))

  return (
    <ListMenu
      showingItem={showItem ? true : false}
      folderUid={folderId}
      handleOrderBy={handleOrderBy}>
      <HandleScroll loadMore={loadMoreDebounced}>{items}</HandleScroll>
      {showItem && <SingleView close={closeItemView} id={showItem} />}
    </ListMenu>
  )
}
