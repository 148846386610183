import styled from "styled-components"

export const StyledSection = styled.section`
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: center;
`

export const StyledContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

  padding: 1.5rem 2.5rem 0;

  width: 100%;
  max-width: 1220px;

  @media (min-width: 1280px) {
    padding: 1.25rem 0 0;
  }
`

export const StyledGrid = styled.div`
  display: grid;
  grid-template-areas: "search icons";
  grid-template-columns: 1fr min-content;
  justify-content: space-between;
  width: 100%;

  @media (min-width: 758px) {
    grid-template-areas: "logo search icons";
    grid-template-columns: 1fr 1fr 1fr;
  }
`
