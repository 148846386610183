import { memo } from "react"
import { Svg } from "./Svg"
import { ICON_SIZE } from "./Svg.styles"
import { BaseComponent } from "./BaseComponent"

type Props = BaseComponent & {
  color?: string
  size?: ICON_SIZE
}

export const CheckIcon = memo(({ className, size, color }: Props) => (
  <Svg size={size} viewBox="0 0 24 24" className={className}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      style={{
        stroke: color || "var(--navbar-icon-color, #374151)",
      }}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 13l4 4L19 7"
      />
    </svg>
  </Svg>
))

CheckIcon.displayName = "CheckIcon"
