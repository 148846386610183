import { memo } from "react"
import { useLocation } from "react-router-dom"
import { ORDER } from "types/List"
import { StyledHeader, StyledTitle } from "./Header.styles"
import { FilterBox } from "./FilterBox"
import { Breadcrumb } from "jsx/components/breadcrumb"
import get from "lodash/get"
import { useTreeviewStore } from "stores"
import { findPath } from "utils/TreeviewFindPath"

type Props = {
  readonly folderUid: string | undefined
  readonly orderBy: ORDER
  readonly hideOrder: boolean

  /** Methods */
  changeOrderBy: (value: ORDER) => void
}

export const Header = memo(
  ({ folderUid, orderBy, hideOrder, changeOrderBy }: Props) => {
    const location = useLocation()
    const { treeview } = useTreeviewStore()

    if (hideOrder) {
      return null
    }

    const onClickChangeOrder = (order: ORDER) => {
      changeOrderBy(order)
    }

    // Fallback name
    // Fallback if we can't find the folder
    // Get latest path name from location
    const locationSplitted = location.pathname.split("/")
    let folderName = decodeURIComponent(
      locationSplitted[locationSplitted.length - 1],
    )

    const paths = findPath(folderUid, treeview)
    if (Array.isArray(paths) && paths.length > 0) {
      paths.pop()

      const p = get(treeview, paths.join("."))
      if (p?.name) {
        folderName = p.name
      }
    }

    return (
      <div>
        {folderUid && <Breadcrumb folderUid={folderUid} />}
        <StyledHeader>
          <div>
            <StyledTitle>{folderName}</StyledTitle>
          </div>
          <FilterBox
            label="Sort by"
            value={orderBy}
            onChange={onClickChangeOrder}
          />
        </StyledHeader>
      </div>
    )
  },
)

Header.displayName = "Header"
