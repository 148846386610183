import { useState } from "react"
import {
  StyledBox,
  StyledFilterBox,
  StyledFilterLabel,
} from "./FilterBox.styles"
import { FilterBoxDropdown } from "./FilterBoxDropdown"
import { Dropdown } from "jsx/atoms"
import { FilterBoxDropdownItem } from "./FilterBoxDropdownItem"
import { ORDER } from "types/List"

type FilterBoxProps = {
  readonly label: string
  // Selected value
  readonly value: string

  /** Methods */

  onChange: (order: ORDER) => void
}

const SORTBY = [
  { label: "Latest", order: ORDER.LATEST },
  {
    label: "Name a-z",
    order: ORDER.NAME_ASC,
  },
  {
    label: "Name z-a",
    order: ORDER.NAME_DESC,
  },
]

export const FilterBox = ({ value, label, onChange }: FilterBoxProps) => {
  const [isShowing, setIsShowing] = useState(false)

  const onClickShow = () => setIsShowing(!isShowing)
  const onClickHide = () => setIsShowing(false)

  const onChangeSort = (order: ORDER) => {
    onChange(order)
    setIsShowing(false)
  }

  let sortByLabel = ""
  const dropdownList = SORTBY.map((b) => {
    if (b.order === value) {
      sortByLabel = b.label
    }
    return (
      <FilterBoxDropdownItem
        key={b.label}
        label={b.label}
        active={value === b.order}
        onClick={() => onChangeSort(b.order)}
      />
    )
  })

  return (
    <StyledBox>
      <StyledFilterBox onClick={onClickShow} data-active={isShowing}>
        <StyledFilterLabel>
          {label} <span>{sortByLabel}</span>
        </StyledFilterLabel>
        <figure>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            data-active={isShowing}
            fill="none"
            viewBox="0 0 24 24"
            stroke="var(--details-box-header-arrow-color)"
            strokeWidth={2}>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </figure>
      </StyledFilterBox>

      {isShowing && (
        <Dropdown hide={onClickHide}>
          <FilterBoxDropdown>{dropdownList}</FilterBoxDropdown>
        </Dropdown>
      )}
    </StyledBox>
  )
}
