import styled from "styled-components"

export const StyledContent = styled.section`
  margin: 1rem 0 1.5rem;
`

export const StyledH1 = styled.h1`
  font-weight: 700;
  font-size: 1.5rem;
  letter-spacing: 0.25px;
`

export const StyledSubTitle = styled.p`
  color: var(--header-sub-text-color);

  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;

  padding-top: 0.75rem;
`
