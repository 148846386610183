import { create } from "zustand"
import { createTrackedSelector } from "react-tracked"
import { State } from "./State"
import { setOpen } from "./setOpen"

export const useOpenDirsStore = createTrackedSelector(
  create<State>((set, get) => ({
    list: [],

    /** Methods */
    setOpen: (uid) => setOpen(set, get, uid),
  })),
)
