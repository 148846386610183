import styled from "styled-components"

export const StyledAppSection = styled.section`
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  height: calc(100vh - 101px - 2rem);
  transition: margin-top 600ms;
  width: 100%;

  @media (min-width: 768px) and (max-width: 1279px) {
    height: calc(100vh - 101px - 1.875rem);
    padding-top: 1.875rem;
  }
  @media (min-width: 1280px) {
    padding-top: 2.25rem;
  }
`

export const StyledAppInner = styled.div`
  flex-grow: 1;
  display: flex;
  margin: 0 auto;
  max-width: 1220px;
  transition: width 200ms ease-in-out;
  width: 100%;

  @media (max-width: 1280px) {
    padding: 0 2.5rem;
    width: calc(100% - 5rem);
  }
`
