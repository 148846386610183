import {
  StyledModal,
  StyledContent,
  StyledContentInner,
  StyledContainer,
  StyledCatalogUl,
  StyledCatalogLi,
  StyledItemText,
  StyledHeader,
} from "./DropdownMenu.styles"

import { Treeview } from "types"

const collator = new Intl.Collator("en", {
  sensitivity: "base",
  numeric: true,
  usage: "sort",
})

type Props = {
  readonly parentName: string
  readonly nodes: Treeview[]

  /** Methods */

  onClose: (url: string) => void
}

export const DropdownMenu = ({ nodes, parentName, onClose }: Props) => {
  const onClickNode = (url: string) => onClose(url)

  // Sort nodes
  const nodesSorted = nodes.sort((a, b) => collator.compare(a.name, b.name))

  const parentNameEncoded = encodeURIComponent(parentName).toLowerCase()

  const rows = nodesSorted.map((n2) => {
    const url = `/${parentNameEncoded}/${encodeURIComponent(
      n2.name.toLowerCase(),
    )}`

    const subRows = n2.nodes.map((n2sub) => {
      const subUrl = `${url}/${encodeURIComponent(n2sub.name.toLowerCase())}`

      return (
        <StyledCatalogLi key={n2sub.name} onClick={() => onClickNode(subUrl)}>
          <StyledItemText>{n2sub.name}</StyledItemText>
        </StyledCatalogLi>
      )
    })

    return (
      <StyledContainer key={n2.name}>
        <StyledHeader onClick={() => onClickNode(url)}>
          <h4>{n2.name}</h4>
        </StyledHeader>
        <StyledCatalogUl>{subRows}</StyledCatalogUl>
      </StyledContainer>
    )
  })

  return (
    <StyledModal>
      <StyledContent>
        <StyledContentInner>{rows}</StyledContentInner>
      </StyledContent>
    </StyledModal>
  )
}
