import { memo } from "react"
import { Helmet } from "react-helmet"

type Props = {
  readonly title?: string
  readonly description?: string
  readonly keywords?: string
}

export const SiteTitle = memo(
  ({ title = "", description = "", keywords = "" }: Props) => {
    const pageTitle = title
      ? `${title} - ${process.env.REACT_APP_TITLE}`
      : process.env.REACT_APP_TITLE

    const pageDescription = description
      ? `${description} - ${process.env.REACT_APP_DESCRIPTION}`
      : process.env.REACT_APP_DESCRIPTION

    return (
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        {keywords && <meta name="keywords" content={keywords} />}
      </Helmet>
    )
  },
)

SiteTitle.displayName = "SiteTitle"
