import styled from "styled-components"

export const StyledCompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 430px;
`

export const StyledIconFigure = styled.figure<{ $height: string }>`
  height: ${({ $height }) => `${$height}px`};

  img {
    display: block;
    height: 100%;
  }
`

export const StyledCompanyDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
`

export const StyledCompanyText = styled.p`
  color: var(--footer-text-color);
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0.02em;

  &:nth-child(2) {
    padding: 0.5rem 0;
  }

  @media (min-width: 768px) {
    font-size: 0.875rem;
  }
`
