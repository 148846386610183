import { useRef, useState, useCallback, useEffect } from "react"
import type { ListPreviewImage } from "types"
import { StyledPicture, StyledImg } from "./ImageView.styles"

type Props = {
  readonly preview: ListPreviewImage[]
}

export const ImageView = ({ preview }: Props) => {
  const observerRef = useRef<IntersectionObserver | null>(null)
  const imgRef = useRef<HTMLImageElement | null>(null)
  const [isLoaded, setIsLoaded] = useState(false)

  const observerCallback = useCallback(
    (entries) => {
      if (entries[0].isIntersecting && observerRef?.current) {
        observerRef.current.disconnect()
        setIsLoaded(true)
      }
    },
    [observerRef],
  )

  useEffect(() => {
    if ("loading" in HTMLImageElement.prototype) {
      setIsLoaded(true)
      return
    }

    // Sanity check refs
    if (!observerRef.current || !imgRef.current) {
      return
    }

    observerRef.current = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: "200px 0px",
    })

    observerRef.current.observe(imgRef.current)
    return () => {
      if (observerRef?.current) {
        observerRef.current.disconnect()
      }
    }
  }, [observerCallback])

  // If no preview exist, just return
  if (!preview) {
    return null
  }

  // Get available image thumb
  const thumb =
    preview[5] ||
    preview[4] ||
    preview[6] ||
    preview[3] ||
    preview[2] ||
    preview[1] ||
    null

  if (thumb === null) {
    return null
  }

  return (
    <StyledPicture>
      {thumb["avif"] && <source type="avif" srcSet={thumb["avif"]} />}
      {thumb["webp"] && <source type="webp" srcSet={thumb["webp"]} />}
      <StyledImg
        ref={imgRef}
        decoding="async"
        src={isLoaded ? thumb["jpeg"] : ""}
        alt=""
        loading="eager"
      />
    </StyledPicture>
  )
}
