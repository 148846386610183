import { StyledHeader } from "./Header.styles"

type Props = {
  readonly text: string
}

export const Header = ({ text }: Props) => (
  <StyledHeader>
    <h4>{text}</h4>
  </StyledHeader>
)

Header.displayName = "Header"
