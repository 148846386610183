import {
  StyledCompanyDetails,
  StyledCompanyText,
  StyledIconFigure,
  StyledCompanyInfo,
} from "./CompanyInfo.styles"

export const CompanyInfo = () => {
  return (
    <StyledCompanyInfo>
      <StyledIconFigure
        $height={process.env.REACT_APP_FOOTER_IMAGE_HEIGHT || "38"}>
        <img src={process.env.REACT_APP_LOGO_2x} alt="logo" />
      </StyledIconFigure>

      <StyledCompanyDetails>
        <StyledCompanyText>
          {process.env.REACT_APP_COMPANY_SHORT_TEXT || ""}
        </StyledCompanyText>
      </StyledCompanyDetails>
    </StyledCompanyInfo>
  )
}
