import { GetState, SetState } from "zustand"
import Cookies from "js-cookie"
import cloneDeep from "lodash/cloneDeep"
import { clog } from "utils"
import { MediabagItem } from "types/Mediabag"
import { State, MEDIABAG_COOKIE, COOKIE_EXPIRE } from "./State"

export const add = async (
  set: SetState<State>,
  get: GetState<State>,
  fileId: string,
  resolutionId: string | null,
) => {
  const { items } = get()
  const itemsCloned = cloneDeep(items)

  // Check if product variant exist in bag
  const pvIndex = itemsCloned.findIndex((obj) => obj.fileId === fileId)

  if (pvIndex === -1) {
    // Add product variant item to bag
    const payload: MediabagItem = {
      fileId,
      resolutionId,
    }
    itemsCloned.push(payload)

    // Update state
    set({ items: itemsCloned, amount: Object.keys(itemsCloned).length })

    // Update cookie
    Cookies.set(MEDIABAG_COOKIE, JSON.stringify(itemsCloned), {
      expires: COOKIE_EXPIRE,
    })

    clog("new product variant added to bag", itemsCloned)
  }
}
