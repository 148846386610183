import styled from "styled-components"

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledGrid = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`

export const StyledNoTagsText = styled.p`
  font-size: 13px;
`
