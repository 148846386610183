/* eslint-disable @typescript-eslint/naming-convention */
import axios, { AxiosRequestHeaders } from "axios"

// TODO: Rename damAPI to mediabank API
const damAPI = axios.create({
  baseURL: `${process.env.REACT_APP_API_DAM}`,
  timeout: 30000,
  responseType: "json",
  headers: {
    customer: process.env.REACT_APP_CUSTOMER_UID || "",
    "Invenseit-Version": "2022-12-27",
  },
})

damAPI.interceptors.request.use(
  (config) => {
    const newConfig = { ...config }
    const authData = sessionStorage.getItem("auth")
    if (authData) {
      const token = JSON.parse(authData)
      if (newConfig.headers === undefined) {
        newConfig.headers = {} as AxiosRequestHeaders
      }
      // Authorization header
      newConfig.headers["Authorization"] = `Bearer ${token}`
    }
    return newConfig
  },
  (error) => Promise.reject(error),
)

damAPI.interceptors.response.use(
  (resp) => resp,
  (err) => {
    throw err
  },
)

export { damAPI }
