import { useEffect } from "react"
// import { useNavigate } from "react-router-dom"
import { cErr } from "utils"
import { SideMenu, DocumentView, ImageView, Movie } from "./components"
import { Overlay } from "./Overlay"
import { downloadFileHandler } from "./utils"
import { StyledColumn, StyledGrid, StyledCenter } from "./SingleView.styles"
import { DEFAULT_RESOLUTION } from "./constants"
import { useSingleViewStore } from "./store"
import { clog } from "utils"
import { SiteTitle } from "jsx/components/SiteTitle"
import { useMediabankAPI } from "services"

type Props = {
  readonly id: string

  /** Methods */

  // Close single view
  close: () => void
}

export const SingleView = ({ id, close }: Props) => {
  // const navigate = useNavigate()
  const singleStore = useSingleViewStore()
  const { reset } = singleStore

  // Fetch data
  const fileReq = useMediabankAPI(`/public/file/${id}`, false)
  const tagReq = useMediabankAPI(`/public/file/${id}/tag`)

  // Disable body scroll
  useEffect(() => {
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = "unset"
    }
  }, [])

  // Handle keyup events
  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === "Escape") close()
    }

    window.addEventListener("keyup", handleKeyUp)
    return () => {
      reset() // Reset store
      window.addEventListener("keyup", handleKeyUp)
    }
  }, [close, reset])

  // Show or hide side panel
  const manageSidePanel = () => {
    singleStore.managePanel()
  }

  const onClickDownloadOriginalFile = async () => {
    if (!fileReq.data) {
      return
    }
    // Download original file
    const filename = `${fileReq.data.filename}.${fileReq.data.file_extension}`
    try {
      await downloadFileHandler(fileReq.data, filename, DEFAULT_RESOLUTION)
    } catch (err) {
      cErr("unable to download file", err)
      // TODO: show error message to user
    }
  }

  if (!tagReq.data || !Array.isArray(tagReq.data) || !fileReq.data) {
    return (
      <Overlay
        showPanel={singleStore.showPanel}
        onClickHidePanel={manageSidePanel}
        close={close}
        onClickDownload={onClickDownloadOriginalFile}
      />
    )
  }

  let jsxContent: JSX.Element | null = null
  if (["pdf", "eps", "xls", "xlsx"].includes(fileReq.data.file_extension)) {
    jsxContent = <DocumentView file={fileReq.data} />
  } else if (["jpeg", "jpg", "png"].includes(fileReq.data.file_extension)) {
    jsxContent = <ImageView preview={fileReq.data.preview} />
  } else if (fileReq.data.file_extension === "m4v") {
    jsxContent = <Movie file={fileReq.data} />
  } else {
    clog("unhandled document type", fileReq.data)
    close()
  }

  // Generate a list of keywords of tags for metadata
  const keywords = tagReq.data?.map((tag) => tag.name).join(", ")

  return (
    <>
      <SiteTitle title={fileReq.data.filename} keywords={keywords} />
      <Overlay
        showPanel={singleStore.showPanel}
        close={close}
        onClickHidePanel={manageSidePanel}
        onClickDownload={onClickDownloadOriginalFile}>
        <StyledColumn>
          <StyledGrid $showPanel={singleStore.showPanel}>
            <StyledCenter>{jsxContent}</StyledCenter>
            {singleStore.showPanel && (
              <SideMenu file={fileReq.data} fileTags={tagReq.data} />
            )}
          </StyledGrid>
        </StyledColumn>
      </Overlay>
    </>
  )
}
