import { useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import useSWRInfinite from "swr/infinite"
import { clog } from "utils"
import { useSearchStore, useDocumentListStore } from "stores"
import type { ORDER } from "types/List"
import { LoadingContainer } from "../components/LoadingContainer"
import { Template } from "../FolderList/Template"
import { sortItems } from "../FolderList/utils"
import type { ListFileItem } from "types"

const getKey = (
  pageIndex: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  previousPageData: any,
  orderBy: string,
  searchTerm: string,
) => {
  if (previousPageData && !previousPageData.length) {
    // reached the end
    return null
  }

  if (!searchTerm) {
    return null
  }

  let url = `${process.env.REACT_APP_API_DAM}/public/search?q=${searchTerm}&order=${orderBy}`
  if (pageIndex > 0) {
    url += `&offset=${pageIndex * 50}`
  }

  return url
}

export const Search = () => {
  const { elem } = useParams()
  const { searchTerm, updateTerm } = useSearchStore()
  const { orderBy, updateOrderBy } = useDocumentListStore()
  const [searchParams, setSearchParams] = useSearchParams()
  const [showItem, setShowItem] = useState<string | undefined>(undefined)

  // Fetch data from API
  const { data, error, size, setSize, mutate, isValidating } = useSWRInfinite(
    (pageIndex, previousPageData) =>
      getKey(pageIndex, previousPageData, orderBy, searchTerm),
    {
      initialSize: 2,
      revalidateAll: false,
      revalidateFirstPage: false,
      persistSize: true,
    },
  )

  useEffect(() => {
    if (elem && elem !== searchTerm) {
      updateTerm(elem)
    }

    return () => {
      // clean up search input field
      updateTerm("")
    }
  }, [])

  const handleOrderBy = (value: ORDER) => {
    const orderParam = searchParams.get("order")
    if (orderParam !== value) {
      // add value to url
      setSearchParams({ order: value })
      updateOrderBy(value)
      mutate() // Clear list
    }
  }

  const isRefreshing = isValidating && data && data.length === size
  const isLoadingInitialData = !data && !error
  const fileItems: ListFileItem[] = data ? [].concat(...data) : []

  const loadMore = async () => {
    if (elem && !isRefreshing && !isLoadingInitialData) {
      clog("search page - load more. Search: ", elem)
      setSize((prevSize) => prevSize + 1)
    }
  }

  if (isLoadingInitialData) {
    return <LoadingContainer />
  }

  // Sort items by orderBy
  const sortedDocuments = sortItems(fileItems, orderBy)

  return (
    <Template
      folderId={undefined}
      data={sortedDocuments}
      loadMore={loadMore}
      handleOrderBy={handleOrderBy}
      showItem={showItem}
      setShowItem={setShowItem}
    />
  )
}
