import { TagItem, Header } from "../../atoms"
import { FileTag } from "types"
import { StyledContent, StyledGrid, StyledNoTagsText } from "./Tags.styles"

type Props = {
  readonly tags: FileTag[]
}

export const Tags = ({ tags }: Props) => {
  let innerContent: JSX.Element | null = null
  if (tags.length) {
    const list = tags.map((t) => <TagItem key={t.id} name={t.name} />)
    innerContent = <StyledGrid>{list}</StyledGrid>
  } else {
    innerContent = <StyledNoTagsText>No tags available</StyledNoTagsText>
  }

  return (
    <StyledContent>
      <Header title="Tags" />
      {innerContent}
    </StyledContent>
  )
}
