import styled from "styled-components"

export const StyledContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 24px;
`

export const StyledButton = styled.button`
  appearance: none;
  background-color: var(--company-color);
  border: 1px solid var(--company-color);
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 600;
  height: 44px;
  margin: 0;
  line-height: 16px;
  outline: none;
  user-select: none;
  width: 100%;

  svg {
    margin-right: 10px;
    height: 24px;
    width: 24px;
  }
`
