import styled from "styled-components"

export const StyledField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`

export const StyledLabel = styled.label`
  color: #374151;
  font-size: 13px;
  letter-spacing: 0.25px;
  font-weight: 700;
  margin-bottom: 10px;
`

export const StyledInputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

export const StyledInput = styled.input`
  background: #fff;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  color: var(--text-body-color);
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  line-height: 42px;
  padding: 0 8px;
  outline: none;
  transition: border 250ms ease-in;
  width: 100%;

  &:hover {
    border: 1px solid var(--border-input-hover);
    cursor: pointer;
  }
  &:focus {
    border: 1px solid var(--border-input-hover);
  }
  &[data-active="true"] {
    border: 1px solid var(--border-input-hover);
  }
`

export const StyledUl = styled.ul<{ width: number }>`
  list-style-type: none;
  width: ${({ width }) => (width ? `${width}px` : `100%`)};
`

export const StyledArrowPosition = styled.span<{ deg: number }>`
  position: absolute;
  right: 10px;
  top: 30%;

  svg {
    stroke: var(--text-body-color);

    height: 18px;
    width: 18px;

    transform: rotate(${({ deg }) => `${deg}deg`});
    transition-duration: 450ms;
  }
`
