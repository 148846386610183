import styled from "styled-components"

export const StyledItem = styled.div`
  cursor: pointer;
  padding: 0 10px;
`

export const StyledText = styled.p`
  color: var(--color-grey-700);

  font-size: 14px;
  font-weight: 500;
  line-height: 1.5rem;
`
