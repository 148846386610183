import styled from "styled-components"
import { StyledArticle } from "./ListMenuItem"
import { StyledPicture } from "./components/ImageElement/ImageElement.styles"
import { pulse } from "jsx/styles/Keyframes"

const StyledPic = styled(StyledPicture)`
  animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  background-color: var(--color-grey-200);
  border-radius: 0.375rem;
  margin-bottom: 0.75rem;
  opacity: 1;
`

const StyledText = styled.div`
  animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  background-color: var(--color-grey-200);
  border-radius: 0.375rem;
  height: 1.5rem;
  width: 100%;
`

export const SkeletonListItem = () => (
  <StyledArticle>
    <StyledPic />
    <StyledText />
  </StyledArticle>
)
