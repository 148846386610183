import { createGlobalStyle } from "styled-components"

/* eslint-disable-next-line @typescript-eslint/naming-convention */
export const Token = createGlobalStyle`
:root {
  --color-grey-25:  #FCFCFD;
  --color-grey-50:  #F8FAFC;
  --color-grey-100: #EEF2F6;
  --color-grey-200: #E3E8EF;
  --color-grey-300: #CDD5DF;
  --color-grey-400: #9AA4B2;
  --color-grey-500: #697586;
  --color-grey-600: #4B5565;
  --color-grey-700: #364152;
  --color-grey-800: #202939;
  --color-grey-900: #121926;

  /** Basic setup */
  --font-family: 'Futura-pt', -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;

  --header-text-color: var(--color-grey-800);
  --header-sub-text-color: var(--color-grey-600);
  --text-body-color: var(--color-grey-600);
  --text-link-color: hsl(221, 83%, 53%);

  --border-color: var(--color-grey-200);
  --border-input-hover: var(--color-grey-400);

  --company-color: ${process.env.REACT_APP_COMPANY_COLOR};
  --company-color-darker: ${process.env.REACT_APP_COMPANY_COLOR_DARKER};

  /* Header */

  --navbar-icon-color: var(--color-grey-500);
  --navbar-icon-color-hover: var(--color-grey-700);

  /* Sidebar */

  --sidebar-border-left-active-color: hsl(221, 39%, 11%);

  /* Buttons */

  // Add to basket button
  --btn-add-to-basket-background-color: #fff;
  --btn-add-to-basket-border-color: #6b7280;
  --btn-add-to-basket-text-color: #6b7280;

  /* Product details */

  --details-box-header-background-color-hover: hsl(210, 20%, 98%);
  --details-box-header-arrow-color: hsl(215, 28%, 17%);

  /* Auth page */

  --auth-form-error-text-color: #be123c;
  --auth-form-success-text-color: #15803d;

  /* Footer */

  --footer-background-color: #fff;

  --footer-title-text-color: #0f172a;
  --footer-text-color: #64748b;
  --footer-social-media-icon-color: rgb(107, 114, 128);

  // Footer copywrite
  --footer-copywright-background-color: #F9FAFB;
  --footer-copywrite-text-color: var(--color-grey-600);
  --footer-copywrite-social-icons-color: #98A2B3;
}
`
