import type { ListPreviewImage } from "types"
import { useAuthStore } from "stores"
import { StyledPicture } from "./ImageElement.styles"

type Props = {
  readonly preview: ListPreviewImage[]
}

export const ImageElement = ({ preview }: Props) => {
  const authStore = useAuthStore()

  // Get image thumb
  const thumb = preview[3] || preview[2] || preview[1] || null
  if (thumb === null) {
    return <StyledPicture />
  }

  if (authStore.isAuthed) {
    thumb["avif"] = thumb["avif"] + `?token=${authStore.token}`
    thumb["jpeg"] = thumb["jpeg"] + `?token=${authStore.token}`
    thumb["webp"] = thumb["webp"] + `?token=${authStore.token}`
  }

  return (
    <StyledPicture>
      {thumb["avif"] && <source type="image/avif" srcSet={thumb["avif"]} />}
      {thumb["webp"] && <source type="image/webp" srcSet={thumb["webp"]} />}
      <img src={thumb["jpeg"]} alt="" />
    </StyledPicture>
  )
}
