import { useState } from "react"
import { PICTURE_LOADING_TYPE, ImageFormats } from "@invenseit/storybook-ui"
import { bytesToSize } from "functions/size"
import {
  StyledItem,
  StyledProductInfo,
  StyledName,
  StyledSubInfo,
  StyledImgContainer,
  StyledSvgContainer,
  RemoveIcon,
  StyledPicture,
  StyledRow,
} from "./RowItem.styles"
import { getImageDimensions } from "utils"
import { File } from "types"
import { StyledDownloadIcon } from "./RowItem.styles"

type Props = {
  readonly item: File
  readonly isRemote: boolean
  readonly token?: string
  readonly resolutionId: string | null

  /** Methods */

  // Download the file
  onClickDownload: () => void
  // User click on remove item
  onClickRemove: () => void
}

export const RowItem = ({
  item,
  isRemote,
  resolutionId,
  onClickDownload,
  onClickRemove,
}: Props) => {
  const [hasImageLoaded, setHasImageLoaded] = useState(false)
  const imageHasLoaded = () => setHasImageLoaded(true)

  const imgObj: ImageFormats = {
    avif: undefined,
    webp: undefined,
    jpeg: "",
  }

  const thumb =
    item.preview?.[3] || item.preview?.[2] || item.preview?.[1] || null
  if (thumb) {
    imgObj.avif = thumb["avif"]
    imgObj.jpeg = thumb["jpeg"]
    imgObj.webp = thumb["webp"]
  }

  let subText = ""
  if (resolutionId) {
    const resolution = item.resolutions.find((r) => r.id === resolutionId)
    if (resolution) {
      subText = `${resolution.width}x${resolution.height}`

      const itemSize = bytesToSize(resolution.size)
      subText += `, ${itemSize}`
    }
  } else {
    const imageDimensions = getImageDimensions(item.metadata)
    subText = imageDimensions ? `${imageDimensions}` : ""

    const itemSize = bytesToSize(item.size)
    if (subText) {
      subText += `, ${itemSize}`
    } else {
      subText = itemSize
    }
  }

  const filename = `${item.filename}.${item.file_extension}`

  return (
    <StyledItem>
      <StyledProductInfo>
        {imgObj.jpeg && (
          <StyledImgContainer>
            <StyledPicture
              type={PICTURE_LOADING_TYPE.LAZY}
              image={imgObj}
              $loaded={hasImageLoaded}
              onImageLoaded={imageHasLoaded}
              alt="product"
            />
          </StyledImgContainer>
        )}
        <div>
          <StyledName>{filename}</StyledName>
          <StyledSubInfo>{subText}</StyledSubInfo>
        </div>
      </StyledProductInfo>

      <StyledRow>
        <StyledSvgContainer onClick={onClickDownload}>
          <StyledDownloadIcon />
        </StyledSvgContainer>
        {!isRemote && (
          <StyledSvgContainer onClick={onClickRemove}>
            <RemoveIcon />
          </StyledSvgContainer>
        )}
      </StyledRow>
    </StyledItem>
  )
}
