import styled from "styled-components"
import { ChevronUp } from "icons"

export const StyledContent = styled.div`
  position: relative;
  width: 100%;
`

export const StyledItems = styled.section`
  margin: 0;
  padding: 0;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;

  a {
    // Solve grid from blowing out of the container
    min-width: 0;
  }
`

export const StyledScrollUpCircle = styled.div`
  position: fixed;
  bottom: 50px;
  right: 20px;
  z-index: 999;
  visibility: hidden;

  &[data-visible="true"] {
    visibility: visible;
  }
`

export const StyledScrollButton = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;

  align-items: center;
  display: flex;
  justify-content: center;

  height: 38px;
  width: 38px;
  transition: background-color 250ms ease;

  &[data-visible="true"] {
    background-color: var(--color-grey-600);
  }
`

export const StyledChrevronUp = styled(ChevronUp)`
  fill: #fff;
  height: 30px;
  width: 30px;
`
