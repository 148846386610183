import styled from "styled-components"

const StyledHeader = styled.header`
  padding-bottom: 12px;
`

type Props = {
  readonly title: string
}

export const Header = ({ title }: Props) => (
  <StyledHeader>
    <h4>{title}</h4>
  </StyledHeader>
)
