import { useEffect, useState, ChangeEvent, KeyboardEvent } from "react"
import { useNavigate } from "react-router-dom"
import { useSearchStore } from "stores"
import { StyledSection, StyledContent, StyledGrid } from "./Navbar.styles"
import { SearchContent } from "./components/Search"
import { Logo } from "./components/Logo"
import { Menu } from "./organisms"
import { useTreeviewStore } from "stores"
import { Navigation } from "./components/Navigation"

export const Navbar = () => {
  const navigate = useNavigate()
  const [searchInput, setSearchInput] = useState("")
  const { searchTerm, updateTerm } = useSearchStore()
  const { treeview } = useTreeviewStore()

  useEffect(() => {
    // Clear search term when navigating away from /search
    setSearchInput(searchTerm || "")
  }, [searchTerm])

  const onEnterSearch = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && searchInput) {
      updateTerm(searchInput)
      navigate(`/search/${encodeURIComponent(searchInput)}`)
    }
  }

  const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value)
  }

  return (
    <StyledSection>
      <StyledContent>
        <StyledGrid>
          <Logo />
          <SearchContent
            value={searchInput}
            onChange={handleSearchInput}
            onKeyDown={onEnterSearch}
          />
          <Navigation />
        </StyledGrid>
        <Menu treeview={treeview} />
      </StyledContent>
    </StyledSection>
  )
}
