import { AxiosResponse } from "axios"
import { SetState, GetState } from "zustand"
import { damAPI } from "utils/DamAPI"
import type { ListFileItem } from "types"
import { cErr, clog } from "utils"
import { State } from "./State"

export const getDocumentsByFolderId = async (
  set: SetState<State>,
  get: GetState<State>,
  controller: AbortController,
  folderId: string,
) => {
  if (!folderId) {
    return
  }

  const { isFetchingMore, hasMore, documents, orderBy } = get()

  if (!hasMore) {
    clog("folder list -  we already got all the results we we wanted.")
    return
  }
  if (isFetchingMore) {
    clog("folder list -  already fetching more product variants")
    return
  }

  const documentsLength = documents.length
  let url = `public/file?order=${orderBy}&folder-id=${folderId}`

  if (documentsLength > 0) {
    set({ isFetchingMore: true })
    url += `&offset=${documentsLength}`
  }

  // Fetch documents from API
  try {
    const resp: AxiosResponse<ListFileItem[]> = await damAPI.get(url, {
      signal: controller.signal,
    })
    if (resp?.data) {
      set({
        hasMore: resp.data.length === 50,
        documents: documents.concat(resp.data),
      })
    }
  } catch (err) {
    // TODO: handle
    cErr("~ err", err)
  } finally {
    set({ isFetchingMore: false })
  }
}
