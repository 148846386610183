import useSWR from "swr"
import useSWRInfinite from "swr/infinite"

const baseUrl = process.env.REACT_APP_API_DAM

export const useMediabankAPI = (url: string, immutable?: boolean) => {
  if (!url) {
    throw new Error("url is required")
  }

  const { data, error } = useSWR(`${baseUrl}${url}`, {
    revalidateIfStale: immutable || false,
    revalidateOnFocus: immutable || false,
    revalidateOnReconnect: immutable || false,
  })

  return { data, error }
}

export const useMediabankAPIInfinite = (url: string | null, params: string) => {
  if (!url) {
    throw new Error("url is required")
  }

  const { data, error, size, setSize } = useSWRInfinite(
    (index) =>
      `${baseUrl}${url}?${params}${index > 0 ? `&offset=${index * 50}` : ""}`,
  )

  return { data, error, size, setSize }
}
