import { createGlobalStyle } from "styled-components"

// eslint-disable-next-line @typescript-eslint/naming-convention
export const GlobalStyles = createGlobalStyle`
  * {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: geometricPrecision;

    padding: 0;
    margin: 0;
    outline: none;
  }

  html, body {
    font-family: var(--font-family);
    font-size: 16px;
  }

  @media (max-width: 1280px) {
    html {
      font-size: 87.5%;
    }
  }

  @media (max-width: 768px) {
    html {
      font-size: 75%;
    }
  }

  h1,h2,h3,h4,h5,h6 {
    font-family: var(--font-family)
  }

  h1,h2,h3,h4,h5,h6 {
    color: var(--header-text-color);
    font-weight: 700;
    margin: 0;
    letter-spacing: 0.5px;
  }

  button, input, label, select, li, span {
    font-family: var(--font-family);
  }

  p {
    color: var(--text-body-color);
    font-family: var(--font-family);
    font-size: 13px;
    font-weight: 400;
    margin: 0;
  }

  figure {
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  input[type=text],
  input[type=email],
  input[type=password],
  textarea {
    -webkit-appearance: none;
  }

  .hidden {
    display: none;
  }
`
