import styled, { css } from "styled-components"
import { Picture } from "@invenseit/storybook-ui"
import { PlayButton } from "icons"

export const StyledOverLay = styled.div`
  position: relative;
  height: 100%;

  &:hover {
    svg {
      opacity: 0.8;
    }
  }
`

export const StyledPlayButton = styled(PlayButton)`
  position: absolute;
  height: 35%;
  width: 35%;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const StyledPicture = styled(Picture)<{ $loaded: boolean }>`
  align-items: center;
  display: flex;
  height: inherit;

  opacity: 0;
  transition: opacity 200ms ease-in;

  img {
    height: 100%;
    object-fit: contain;
    max-height: 280px;
    width: 100%;
  }

  ${({ $loaded }) =>
    $loaded &&
    css`
      opacity: 1;
    `}
`
