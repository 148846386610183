import { useState } from "react"
import styled from "styled-components"
import { fadeIn } from "../animations/fade"
import { SectionTitle } from "./components/SectionTitle"

const Content = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;

  @media (min-width: 768px) and (max-width: 1279px) {
    margin: 12px 0;
  }
  @media (min-width: 1280px) and (max-width: 1439px) {
    margin: 14px 0;
  }
  @media (min-width: 1440px) {
    margin: 16px 0;
  }
`

const StyledText = styled.p`
  color: var(--text-body-color);
  font-size: 1rem;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.025em;
`
const ImageFigure = styled.figure`
  margin-top: 16px;
  width: 100%;
`

type ImageProps = {
  loaded: boolean
}

const StyledImage = styled.img<ImageProps>`
  animation: ${fadeIn} 400ms;
  display: block;
  display: ${({ loaded }) => (loaded ? "block" : "none")};
  border-radius: 4px;
  width: 100%;
`

const introImage2xUrl = process.env.REACT_APP_INTRO_IMG_2x
const introImageUrl = process.env.REACT_APP_INTRO_IMG

export const Home = () => {
  const [hasImageLoaded, setHasImageLoaded] = useState(false)

  const OnImageLoad = () => {
    setHasImageLoaded(true)
  }

  let introImage: JSX.Element | null = null
  if (introImage2xUrl && introImageUrl) {
    introImage = (
      <StyledImage
        src={introImage2xUrl}
        srcSet={`${introImageUrl} 1x, ${introImage2xUrl} 2x`}
        onLoad={OnImageLoad}
        alt="intro"
        loaded={hasImageLoaded}
      />
    )
  } else if (introImageUrl) {
    introImage = (
      <StyledImage
        src={introImageUrl}
        onLoad={OnImageLoad}
        alt="intro"
        loaded={hasImageLoaded}
      />
    )
  }

  return (
    <Content>
      <>
        <SectionTitle text={process.env.REACT_APP_HEADER || ""} />
        <TextSection>
          <StyledText>{process.env.REACT_APP_INTRO_MSG}</StyledText>
          {process.env.REACT_APP_CONTACT_MSG && (
            <StyledText>{process.env.REACT_APP_CONTACT_MSG}</StyledText>
          )}
        </TextSection>
      </>

      <ImageFigure>{introImage}</ImageFigure>
    </Content>
  )
}
