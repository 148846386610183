import styled from "styled-components"

export const StyledContent = styled.div`
  grid-area: search;

  align-items: center;
  display: flex;
  padding-right: 1rem;
  width: calc(100% - 1rem);

  @media (min-width: 768px) {
    justify-content: center;
    padding-right: 0rem;
  }
`

export const StyledInput = styled.input`
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 451 451' style='enable-background:new 0 0 451 451;' fill='%23777' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M447.05,428l-109.6-109.6c29.4-33.8,47.2-77.9,47.2-126.1C384.65,86.2,298.35,0,192.35,0C86.25,0,0.05,86.3,0.05,192.3 s86.3,192.3,192.3,192.3c48.2,0,92.3-17.8,126.1-47.2L428.05,447c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4 C452.25,441.8,452.25,433.2,447.05,428z M26.95,192.3c0-91.2,74.2-165.3,165.3-165.3c91.2,0,165.3,74.2,165.3,165.3 s-74.1,165.4-165.3,165.4C101.15,357.7,26.95,283.5,26.95,192.3z'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 10px 50%;
  background-size: 1rem 1rem;
  background-color: #fff;
  border: 1px solid var(--color-grey-200);
  border-radius: 8px;
  color: var(--text-body-color);
  height: 40px;
  font-size: 13px;
  font-weight: 400;
  padding-left: 38px;
  transition: all 200ms ease-in;
  width: 100%;

  &:focus {
    border: 1px solid var(--color-grey-400);
  }

  &:hover {
    border: 1px solid var(--color-grey-400);
  }

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--text-body-color) !important;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--text-body-color) !important;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--text-body-color) !important;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: var(--text-body-color) !important;
  }

  @media (min-width: 768px) {
    max-width: 386px;
  }
`
