import { StyledLi, StyledLink, StyledText } from "./ListItem.styles"

type ListItemProps = {
  readonly label: string
  readonly url: string
}

export const ListItem = ({ url, label }: ListItemProps) => (
  <StyledLi>
    <StyledLink href={url} target="_blank">
      <StyledText>{label}</StyledText>
    </StyledLink>
  </StyledLi>
)
