import { SVGAttributes } from "react"
import { StyledSvg, ICON_SIZE } from "./Svg.styles"
import { BaseComponent } from "./BaseComponent"

type SvgProps = BaseComponent & {
  size?: ICON_SIZE
  viewBox?: SVGAttributes<SVGSVGElement>["viewBox"]
  children: React.ReactNode
}

export const Svg = ({
  size = ICON_SIZE.DEFAULT,
  children,
  className,
  viewBox,
}: SvgProps) => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox || "0 0 32 32"}
    size={size}
    className={className}>
    {children}
  </StyledSvg>
)
