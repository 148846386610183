import styled from "styled-components"
import { CheckIcon } from "icons/Check"

const StyledItem = styled.li`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;

  height: 40px;
  padding: 0 10px 0;
  min-width: 154px;
  transition: all 200ms ease-in;

  &:hover {
    text-underline-offset: 2px;
    text-decoration: underline;
    text-decoration-color: var(--text-body-color);
  }
`

const StyledCheckIcon = styled(CheckIcon)`
  margin-right: 10px;
`

const StyledText = styled.p`
  color: var(--text-body-color);

  font-size: 0.875rem;
  font-weight: 400;
  line-height: 16px;

  text-decoration-color: var(--text-body-color);
  text-underline-offset: 2px;

  &[data-active="true"] {
    font-weight: 600;
  }
`

type Props = {
  readonly label: string
  readonly active: boolean

  /** Methods */
  onClick: () => void
}

export const FilterBoxDropdownItem = ({ label, active, onClick }: Props) => {
  const svgColor = active ? "var(--header-text-color)" : "#fff"
  return (
    <StyledItem onClick={onClick}>
      <StyledCheckIcon color={svgColor} />
      <StyledText data-active={active}>{label}</StyledText>
    </StyledItem>
  )
}
