import styled from "styled-components"

export const StyledOverlay = styled.div`
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
`

type StyledContentProps = {
  top?: number
}

export const StyledContent = styled.div<StyledContentProps>`
  background: #fff;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 15px -3px;
  padding: 0.5rem 0;
  position: absolute;
  top: ${({ top }) => (top ? `${top}px` : "5px")};
  right: 0;
  z-index: 1;
`
