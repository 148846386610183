import { useEffect } from "react"
import { BrowserRouter } from "react-router-dom"
import { useDocumentListStore, useAuthStore } from "../stores"
import { Token } from "./styles/Token"
import { Footer } from "./components/footer"
import { useMediabagStore } from "../stores/mediabag"
import { GlobalStyles } from "./styles/GlobalStyles"
import { SiteTitle } from "./components/SiteTitle"
import { Navbar } from "./components/navbar"
import { Routes } from "./Routes"
import { useTreeviewStore } from "stores/treeview"
import { clog } from "utils"
import { StyledAppSection, StyledAppInner } from "./app.styles"
import { SWRConfig } from "swr"

function getHeaders() {
  const headers = new Headers()
  headers.append("customer", process.env.REACT_APP_CUSTOMER_UID || "")
  // Check if token exist
  const authData = sessionStorage.getItem("auth")
  if (authData) {
    const token = JSON.parse(authData)
    headers.append("Authorization", `Bearer ${token}`)
  }
  return headers
}

const fetcher = (url: string) =>
  fetch(url, { headers: getHeaders() }).then((r) => r.json())

export const App = () => {
  const { initLoad } = useDocumentListStore()
  const mediabagStore = useMediabagStore()
  const authStore = useAuthStore()
  const { get } = useTreeviewStore()

  // Load default config from cookie
  useEffect(() => {
    initLoad()
  }, [])

  useEffect(() => {
    const controller = new AbortController()

    const getTreeview = async () => {
      //setIsloading(true)
      try {
        await get(controller)
      } catch (err) {
        clog("failed to get treeview.", err)
        // TODO: show error page
      }
    }

    getTreeview()
    return () => {
      // cancel the request
      controller.abort()
    }
  }, [])

  // Check if user is authenticated
  useEffect(() => {
    const checkAuth = () => {
      // Get token from sessionStorage
      const tokenStorage = sessionStorage.getItem("auth")
      if (tokenStorage) {
        // Set token in stores
        authStore.updateToken(JSON.parse(tokenStorage))
      }
    }

    checkAuth()
  }, [])

  useEffect(() => {
    // Load mediabag
    mediabagStore.load()
  }, [mediabagStore])

  return (
    <BrowserRouter>
      <SWRConfig value={{ fetcher }}>
        <Token />
        <SiteTitle />
        <GlobalStyles />
        <Navbar />
        <StyledAppSection>
          <StyledAppInner>
            <Routes />
          </StyledAppInner>
          <Footer />
        </StyledAppSection>
      </SWRConfig>
    </BrowserRouter>
  )
}
