import styled from "styled-components"
import { Link } from "react-router-dom"

export const StyledBreadcrumb = styled.section`
  height: 18px;
  padding-bottom: 1.5rem;

  @media (max-width: 1280px) {
    padding-bottom: 1rem;
  }
`

export const StyledNav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`

export const StyledChild = styled.div`
  display: flex;
  flex-direction: row;
`

export const StyledLink = styled(Link)`
  color: var(--text-body-color);
  font-size: 0.8125rem;
  font-weight: 600;
  text-transform: capitalize;
`

export const StyledSpan = styled.span`
  word-break: break-word;
`

export const StyledDivider = styled.div`
  color: var(--text-body-color);
  font-size: 13px;
  font-weight: 600;
  margin: auto 8px;
`
