import styled from "styled-components"
import { fadeIn } from "jsx/animations/fade"

const StyledContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
`
const StyledText = styled.p`
  animation: ${fadeIn} 380ms;
  color: #3b3b4d;
  font-size: 1rem;
  font-weight: 500;
`

type Props = {
  readonly msg: string
}

export const ErrorMessage = ({ msg }: Props) => (
  <StyledContent>
    <StyledText>{msg}</StyledText>
  </StyledContent>
)
