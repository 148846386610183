import { Link, useNavigate } from "react-router-dom"
import { useSWRConfig } from "swr"
import { useAuthStore, useTreeviewStore, useMediabagStore } from "stores"
import {
  StyledContent,
  StyledBagRow,
  StyledProfileIcon,
  StyledMediabagIcon,
  StyledDivider,
  StyledSignoutText,
} from "./Navigation.styles"

export const Navigation = () => {
  const navigate = useNavigate()
  const { cache } = useSWRConfig()
  const authStore = useAuthStore()
  const treeviewStore = useTreeviewStore()
  const mediabagStore = useMediabagStore()

  const onClickSignOut = async () => {
    authStore.signOut()
    // Remove mediabag from store
    mediabagStore.clear()
    // Clear SWR cache
    // eslint-disable-next-line
    const c = cache as any
    c.clear()
    // Reload treeview
    const controller = new AbortController()
    await treeviewStore.get(controller)
    // Redirect to startpage
    navigate("/")
  }

  return (
    <StyledContent>
      {!authStore.isAuthed ? (
        <Link to="/auth/login" style={{ textDecoration: "none" }}>
          <StyledBagRow>
            <StyledProfileIcon />
          </StyledBagRow>
        </Link>
      ) : (
        <StyledBagRow onClick={onClickSignOut}>
          <StyledSignoutText>Sign out</StyledSignoutText>
          <StyledProfileIcon />
        </StyledBagRow>
      )}
      <StyledDivider />
      <Link to="/mediabag" style={{ textDecoration: "none" }}>
        <StyledBagRow>
          <StyledMediabagIcon />
          <p>{mediabagStore.amount}</p>
        </StyledBagRow>
      </Link>
    </StyledContent>
  )
}
