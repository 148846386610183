import styled from "styled-components"

export const StyledHeader = styled.header`
  padding-bottom: 1rem;

  h4 {
    color: var(--footer-title-text-color);
    font-size: 0.875rem;
    letter-spacing: 0.5px;
    line-height: 20px;
    font-weight: 600;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
`
