import { useState, useRef } from "react"
import { useOnClickOutside } from "hooks"
import { Link, useNavigate } from "react-router-dom"
import { StyledItem, StyledText } from "./MenuItem.styles"
import { DropdownMenu } from "./DropdownMenu"
import { Treeview } from "types"

type Props = {
  readonly url: string
  readonly treeview: Treeview
}

export const MenuItem = ({ url, treeview }: Props) => {
  const navigate = useNavigate()
  const [showMenu, setShowMenu] = useState(false)

  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setShowMenu(false))

  const onClickItem = () => setShowMenu(!showMenu)

  const onClickDropDownItem = (url: string) => {
    navigate(url)
    setShowMenu(false)
  }

  const onMouseEnter = () => setShowMenu(true)
  const onMouseLeave = () => setShowMenu(false)

  return (
    <div ref={ref} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Link to={url}>
        <StyledItem data-active={showMenu} onClick={onClickItem}>
          <StyledText>{treeview.name}</StyledText>
        </StyledItem>
      </Link>
      {showMenu && (
        <DropdownMenu
          parentName={treeview.name}
          nodes={treeview.nodes}
          onClose={onClickDropDownItem}
        />
      )}
    </div>
  )
}
