import { useLocation } from "react-router-dom"
import {
  StyledBreadcrumb,
  StyledNav,
  StyledChild,
  StyledSpan,
  StyledLink,
  StyledDivider,
} from "./Breadcrumb.styles"
import { useTreeviewStore } from "stores"
import { findPath } from "utils/TreeviewFindPath"
import get from "lodash/get"

type Props = {
  readonly folderUid: string
}

export const Breadcrumb = ({ folderUid }: Props) => {
  const location = useLocation()
  const { treeview } = useTreeviewStore()

  const paths = findPath(folderUid, treeview)
  if (Array.isArray(paths) && paths.length > 0) {
    paths.pop()
  }

  const locationSplitted = location.pathname.split("/")
  locationSplitted.splice(0, 1)

  let url = ""
  const breadcrumbItems: JSX.Element[] = []

  if (paths) {
    for (let i = 0; i < paths.length; i += 1) {
      if (paths[i] === "nodes") {
        continue
      }

      const p = get(treeview, paths.slice(0, i + 1).join("."))
      url += `/${encodeURIComponent(p.name.toLowerCase())}`

      breadcrumbItems.push(
        <StyledChild key={i.toString()}>
          {i > 1 && <StyledDivider>/</StyledDivider>}
          <StyledLink to={url}>
            <StyledSpan>{p.name}</StyledSpan>
          </StyledLink>
        </StyledChild>,
      )
    }
  }

  return (
    <StyledBreadcrumb>
      <StyledNav>{breadcrumbItems}</StyledNav>
    </StyledBreadcrumb>
  )
}
