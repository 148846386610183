import { useEffect, useState } from "react"
import { PICTURE_LOADING_TYPE, ImageFormats } from "@invenseit/storybook-ui"
import type { ListPreviewImage } from "types"
import { useAuthStore } from "stores"
import {
  StyledPicture,
  StyledOverLay,
  StyledPlayButton,
} from "./VideoElement.styles"
import { IconView } from "./components"

type Props = {
  readonly preview: ListPreviewImage[]
  readonly fileExtension: string
}

export const VideoElement = ({ preview, fileExtension }: Props) => {
  const authStore = useAuthStore()
  const [hasImageLoaded, setHasImageLoaded] = useState(false)
  const [imageObj, setImageObj] = useState<ImageFormats>({ jpeg: "" })

  useEffect(() => {
    const setImageState = () => {
      if (!preview) {
        return
      }

      const thumb = preview[6] || preview[3] || preview[2] || preview[1] || null
      if (thumb === null) {
        return
      }

      const imgObj: ImageFormats = {
        avif: thumb["avif"] || undefined,
        webp: thumb["webp"] || undefined,
        jpeg: thumb["jpeg"],
      }

      if (authStore.token) {
        if (imgObj.avif !== undefined) {
          imgObj.avif += `?token=${authStore.token}`
        }
        if (imgObj.jpeg !== undefined) {
          imgObj.jpeg += `?token=${authStore.token}`
        }
        if (imgObj.webp !== undefined) {
          imgObj.webp += `?token=${authStore.token}`
        }
      }

      setImageObj(imgObj)
    }

    setImageState()
  }, [authStore.isAuthed, authStore.token, preview])

  if (!imageObj.jpeg) {
    return <IconView fileExtension={fileExtension} />
  }

  return (
    <StyledOverLay>
      <StyledPlayButton />
      <StyledPicture
        type={PICTURE_LOADING_TYPE.LAZY}
        image={imageObj}
        $loaded={hasImageLoaded}
        onImageLoaded={() => setHasImageLoaded(true)}
        alt="product-video"
      />
    </StyledOverLay>
  )
}
