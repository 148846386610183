import { StyledHeader } from "./Header.styles"
import styled from "styled-components"
import { StyledBreadcrumb } from "jsx/components/breadcrumb/Breadcrumb.styles"
import { fadeIn, pulse } from "jsx/styles/Keyframes"

const StyledSkeletonHeader = styled(StyledHeader)`
  opacity: 0;
  animation: ${fadeIn} 1s;
  animation-delay: 0.5;
  animation-fill-mode: forwards;

  align-items: baseline;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`

const StyledBread = styled(StyledBreadcrumb)`
  height: 18px;
  min-height: 18px;
  padding-bottom: 0;
  margin-bottom: 1.5rem;

  animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  background-color: var(--color-grey-200);
  border-radius: 0.375rem;
  width: 39%;
`

const StyledTitle = styled.div`
  height 35px;

  animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  background-color: var(--color-grey-200);
  border-radius: 0.375rem;
  width: 100%;
`

export const HeaderSkeleton = () => (
  <StyledSkeletonHeader>
    <StyledBread />
    <StyledTitle />
  </StyledSkeletonHeader>
)
