import { SetState } from "zustand"
import Cookies from "js-cookie"
import { cErr } from "utils"
import { State, MEDIABAG_COOKIE } from "./State"

// Load bag if it exist in cookie
export const load = (set: SetState<State>) => {
  const bag = Cookies.get(MEDIABAG_COOKIE)
  if (bag !== undefined) {
    try {
      const parsed = JSON.parse(bag)
      set({ items: parsed, amount: Object.keys(parsed).length })
    } catch (err) {
      cErr("unable to parse bag.", err)
    }
  }
}
