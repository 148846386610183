import styled from "styled-components"

export const StyledContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 2rem;
  height: 36px;
  width: 100%;
`

export const StyledH1 = styled.h1`
  font-size: 1.75rem;
`

export const StyledAmountText = styled.span`
  color: var(--header-sub-text-color);
  font-size: 1.25rem;
  font-weight: 500;
`

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledButton = styled.button`
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.025em;
  outline: none;
  padding: 0 24px;
  text-align: center;
  height: 38px;
  user-select: none;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`

export const StyledDownloadButton = styled(StyledButton)`
  background-color: var(--company-color);
  border: 1px solid var(--company-color);
  color: #fff;
  min-width: 115px;
  transition: all 300ms ease-in;
`

export const StyledShareButton = styled(StyledButton)`
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: var(--header-text-color);
  margin-right: 1rem;
`
