import { ReactNode } from "react"
import styled from "styled-components"

const StyledWrapper = styled.div`
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid var(--border-color);
  box-shadow: 0 32px 48px 0 rgb(50 50 50 / 18%);
  position: absolute;
  right: 0;
  top: 0;
  z-index: 20;
`

const StyledUl = styled.ul`
  list-style: none;
  padding: 10px 10px;
`

type Props = {
  children: ReactNode
}

export const FilterBoxDropdown = ({ children }: Props) => (
  <StyledWrapper>
    <StyledUl>{children}</StyledUl>
  </StyledWrapper>
)
