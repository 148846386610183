import { ORDER } from "types/List"
import type { ListFileItem } from "types"

const collator = new Intl.Collator("en", {
  sensitivity: "base",
  numeric: true,
  usage: "sort",
})

// Sort documents by ORDER value
export const sortItems = (data: ListFileItem[], orderBy: ORDER) => {
  // Concat products and documents and order them
  if (orderBy === ORDER.NAME_ASC) {
    return data.sort((a, b) => collator.compare(a.filename, b.filename))
  } else if (orderBy === ORDER.NAME_DESC) {
    return data
      .slice()
      .sort((a, b) => collator.compare(a.filename, b.filename))
      .reverse()
  } else if (orderBy === ORDER.LATEST) {
    return data.sort(
      (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime(),
    )
  } else {
    return []
  }
}
