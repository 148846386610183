import styled from "styled-components"

const StyledContent = styled.div`
  margin-bottom: 1.6rem;
`
const StyledTitle = styled.h2`
  color: var(--header-text-color);
  font-weight: 600;
`
const StyledLightText = styled.p`
  color: var(--header-sub-text-color);
  font-size: 13px;
  padding-top: 6px;
`

export const IntroText = () => (
  <StyledContent>
    <StyledTitle>Share the items in your bag</StyledTitle>
    <StyledLightText>
      A new link is created every time there is a change to the bag.
    </StyledLightText>
  </StyledContent>
)
