import { useEffect, useState } from "react"
import useSWRInfinite from "swr/infinite"
import { useLocation, useSearchParams } from "react-router-dom"
import { useTreeviewStore, useDocumentListStore } from "stores"
import { ORDER } from "types/List"
import { findFolderById, findFolderByPath } from "./utils"
import type { ListFileItem, LocationState } from "types"
import { Template } from "./Template"
import { sortItems } from "./utils"
import { cErr } from "utils"
import { SkeletonListMenu } from "jsx/listMenu"
import type { Treeview } from "types/Treeview"

const getKey = (
  pageIndex: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  previousPageData: any,
  folderId: string,
  orderBy: string,
) => {
  if (previousPageData && !previousPageData.length) {
    // reached the end
    return null
  }
  if (!folderId) {
    return null // reached the end
  }
  let url = `${process.env.REACT_APP_API_DAM}/public/file?order=${orderBy}&folder-id=${folderId}`
  if (pageIndex > 0) {
    url += `&offset=${pageIndex * 50}`
  }
  return url
}

export const FolderList = () => {
  const location = useLocation()
  const { orderBy, updateOrderBy } = useDocumentListStore()
  const { treeview } = useTreeviewStore()
  const [folderId, setFolderId] = useState("")
  const [searchParams, setSearchParams] = useSearchParams()
  const [showItem, setShowItem] = useState<string | undefined>(undefined)

  // Fetch data from API
  const { data, size, isLoading, error, setSize, mutate, isValidating } =
    useSWRInfinite(
      (pageIndex, previousPageData) =>
        getKey(pageIndex, previousPageData, folderId, orderBy),
      {
        initialSize: 2,
        revalidateAll: false,
        revalidateFirstPage: false,
        persistSize: true,
      },
    )

  const isInitLoading = isLoading && !data && !error
  const isRefreshing = isValidating && data && data.length === size
  const fileItems: ListFileItem[] = data ? [].concat(...data) : []

  // Used for clearing the cache when changing folder
  useEffect(() => {
    return () => {
      mutate(() => undefined, undefined)
    }
  }, [folderId])

  useEffect(() => {
    const getUrlParam = () => {
      const idParam = searchParams.get("id")
      if (idParam) {
        setShowItem(idParam)
      }
    }

    getUrlParam()
  }, [searchParams, setShowItem])

  useEffect(() => {
    const getFolder = () => {
      if (Object.keys(treeview).length === 0) {
        return
      }

      const locationState = location.state as unknown as LocationState
      if (locationState?.folder_id) {
        // Get folder from treeview
        const folder = findFolderById(locationState.folder_id || "", treeview)
        if (!folder?.id) {
          cErr("Folder not found from locationState")
          // TODO: show empty page view?
          return
        }
        setFolderId(folder.id)
      } else {
        // Find folder from url path
        let folderId = ""

        // Split location pathname
        const pathsSplitted = location.pathname.split("/")
        let foundFolderPath: Treeview = treeview
        pathsSplitted.forEach((path) => {
          if (!path) {
            return
          }

          const foundPath = findFolderByPath(decodeURI(path), foundFolderPath)
          if (foundPath) {
            foundFolderPath = foundPath
            folderId = foundPath.id
          }
        })

        if (folderId) {
          setFolderId(folderId)
        } else {
          cErr("Folder not found from locationPathname")
        }
      }
    }

    getFolder()
  }, [location, treeview])

  const handleOrderBy = (value: ORDER) => {
    const orderParam = searchParams.get("order")
    if (orderParam !== value) {
      // add value to url
      setSearchParams({ order: value })
      updateOrderBy(value)
      // Clear list
      mutate()
    }
  }

  const handleLoadMore = async () => {
    if (folderId && !isRefreshing && !isInitLoading) {
      setSize((prevSize) => prevSize + 1)
    }
  }

  if (isInitLoading) {
    return <SkeletonListMenu itemsToShow={25} />
  } else if (!folderId) {
    // TODO: show error
    return null
  }

  // Sort items by orderBy
  const allItemsOrdered = sortItems(fileItems, orderBy)

  return (
    <Template
      folderId={folderId}
      data={allItemsOrdered}
      loadMore={handleLoadMore}
      handleOrderBy={handleOrderBy}
      showItem={showItem}
      setShowItem={setShowItem}
    />
  )
}
