import { StyledFooter, StyledFooterInner } from "./Footer.styles"
import { CopyWrite, FooterColumns, CompanyInfo, FooterList } from "./components"

// TODO: Dummies for now
import swegmarkconfig from "./configs/swegmark.json"

export const Footer = () => {
  const customer = process.env.REACT_APP_CUSTOMER_UID

  // Load config
  let data: FooterList[] | null = null
  if (customer === "69ceaf70-2335-4d9f-9408-1a18eb34b8fe") {
    data = swegmarkconfig
  }

  return (
    <StyledFooter>
      <StyledFooterInner>
        <CompanyInfo />
        {data && <FooterColumns data={data} />}
      </StyledFooterInner>
      <CopyWrite />
    </StyledFooter>
  )
}
