import { StyledContent, StyledButton } from "./FormButton.styles"

type Props = {
  readonly isLoading: boolean
  readonly text: string
}

// TODO: Implement a loader
// {isLoading ? <Loader size="40" color="white" /> : <>{text}</>}

export const FormButton = ({ isLoading, text }: Props) => (
  <StyledContent>
    <StyledButton type="submit" disabled={isLoading}>
      {text}
    </StyledButton>
  </StyledContent>
)
