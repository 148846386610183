import styled, { css } from "styled-components"
import { Picture } from "@invenseit/storybook-ui"
import { DownloadIcon, XCloseIcon } from "jsx/atoms/icons"

export const StyledItem = styled.div`
  --svg-icon-color: #374151;

  border-bottom: 1px solid #eceff5;

  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 0.8rem 0.5rem;

  &:first-child {
    border-top: 1px solid #eceff5;
  }
`

export const StyledProductInfo = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`

export const StyledName = styled.p`
  color: var(--header-text-color);
  font-size: 1rem;
  font-weight: 600;
  line-height: 20px;
`

export const StyledSubInfo = styled.p`
  color: var(--header-sub-text-color);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 18px;
`

export const StyledImgContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 12px;
  max-width: 96px;

  img {
    object-fit: contain;
    max-height: 85px;
    max-width: 100%;
    width: 68px;
  }
`

export const StyledSvgContainer = styled.div``

export const RemoveIcon = styled(XCloseIcon)`
  cursor: pointer;

  display: block;
  height: 1.4rem;
  width: 1.4rem;

  margin-left: 1.25rem;
  padding-right: 2px;
  transition: opacity 150ms ease-in;

  path {
    stroke: var(--svg-icon-color);
  }
`

export const StyledPicture = styled(Picture)<{ $loaded: boolean }>`
  opacity: 0;
  transition: opacity 300ms ease-in;

  ${({ $loaded }) =>
    $loaded &&
    css`
      opacity: 1;
    `}
`

export const StyledRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`

export const StyledDownloadIcon = styled(DownloadIcon)`
  display: block;
  cursor: pointer;

  height: 1.3rem;
  width: 1.3rem;

  path {
    stroke: var(--svg-icon-color);
  }
`
