import styled from "styled-components"

export const StyledModal = styled.div`
  position: absolute;
  left: 0;
  top: 100px;
  z-index: 1;

  width: 100%;
`

export const StyledContent = styled.div`
  background-color: #fff;
  border-bottom: 1px solid var(--border-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  padding: 1rem 0 1.5rem;
`

export const StyledContentInner = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-content: flex-start;
  column-gap: 5rem;

  min-height: 300px;
  max-height: 340px;

  overflow: hidden;
  max-width: 1220px;
  width: 100%;
`

export const StyledHeader = styled.header`
  cursor: pointer;

  h4 {
    font-size: 14px;
    font-weight: 600;
  }
`

export const StyledContainer = styled.div`
  padding-top: 1rem;
  max-height: 100%;
`

export const StyledCatalogUl = styled.ul`
  display: flex;
  flex-flow: column wrap;
  height: calc(100% - 14px);
  padding-top: 8px;
`

export const StyledCatalogLi = styled.li`
  cursor: pointer;
  list-style-type: none;
  padding-top: 8px;
  padding-right: 5px;

  &:not(:last-child) {
    padding-bottom: 8px;
  }
`

export const StyledItemText = styled.p`
  color: var(--color-grey-800);
  font-size: 14px;
  line-height: 18px;
`
