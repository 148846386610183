import styled from "styled-components"

export const StyledContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 4rem 0;
  width: 100%;

  > div {
    max-width: 380px;
  }
`

export const StyledBox = styled.div`
  border: 1px solid var(--border-color);
  border-radius: 6px;
  padding: 34px 48px;
  width: calc(100% - 48px);
`

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`

export const StyledInputBox = styled.div`
  &:not(:first-child) {
    margin-top: 20px;
  }
`
