import styled from "styled-components"
import { ProfileIcon, BagIcon } from "icons"

export const StyledContent = styled.div`
  grid-area: icons;

  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

export const StyledDivider = styled.span`
  border-left: 1px solid var(--navbar-icon-color);
  height: 24px;

  padding: 0 10px;
`

export const StyledBagRow = styled.div`
  cursor: pointer;

  align-items: center;
  display: flex;
  flex-direction: row;

  transition: all 150ms ease-in;

  &:hover {
    svg {
      stroke: var(--navbar-icon-color-hover);
    }

    p {
      color: var(--navbar-icon-color-hover);
    }
  }

  p {
    color: var(--navbar-icon-color);
    font-size: 14px;
    font-weight: 500;
    margin-left: 8px;
    transition: all 150ms ease-in;
  }
`

export const StyledProfileIcon = styled(ProfileIcon)`
  height: 1.5rem;
  width: 1.5rem;

  margin-right: 20px;

  transition: all 150ms ease-in;
  stroke: var(--navbar-icon-color);
`

export const StyledMediabagIcon = styled(BagIcon)`
  height: 1.5rem;
  width: 1.5rem;

  transition: all 150ms ease-in;
  stroke: var(--navbar-icon-color);
`

export const StyledSignoutText = styled.p`
  padding-right: 8px;
`
