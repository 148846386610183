import { useRef } from "react"
import { useOnClickOutside } from "hooks"
import { StyledOverlay, StyledContent } from "./Dropdown.styles"

type Props = {
  readonly top?: number
  children: React.ReactNode

  /** Methods */
  hide: () => void
}

export const Dropdown = ({ children, hide, top }: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, hide)

  return (
    <StyledOverlay>
      <StyledContent ref={ref} top={top}>
        {children}
      </StyledContent>
    </StyledOverlay>
  )
}
