import { useState, useRef, FormEvent } from "react"
import { Input, Header, FormButton, FormResponse, FormLink } from "./atoms"
import {
  StyledContent,
  StyledBox,
  StyledForm,
  StyledInputBox,
} from "./Auth.styles"

export const ForgotPassword = () => {
  const mailRef = useRef<HTMLInputElement>(null)

  const [isLoading, setIsLoading] = useState(false)
  const [successText, setSuccessText] = useState("")
  const [errorText, setErrorText] = useState("")

  const onClickSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (isLoading) {
      return
    }

    if (!mailRef.current?.value) {
      setErrorText("Invalid mail address.")
      return
    }

    setIsLoading(true)

    // Check if we have an old error message, if so clear it
    if (errorText) setErrorText("")
    if (successText) setSuccessText("")

    const url = `${process.env.REACT_APP_API_ACCOUNT}/v0/auth/forgot-password`
    const resp = await fetch(url, {
      method: "POST",
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "Content-Type": "application/json",
        application: process.env.REACT_APP_APP_UID || "",
        customer: process.env.REACT_APP_CUSTOMER_UID || "",
      },
      body: JSON.stringify({
        email: mailRef.current?.value || "",
      }),
    })

    if (resp.status !== 204) {
      const body = await resp.json()
      setErrorText(body.description)
    } else if (resp.status === 204) {
      setSuccessText("If you have an account, we'll email you a reset link.")
      // Clear mail input field
      mailRef.current.value = ""
    }

    setIsLoading(false)
  }

  return (
    <StyledContent>
      <StyledBox>
        <StyledForm onSubmit={onClickSubmit}>
          <Header
            title="Reset your password"
            subTitle="Enter your email address below and we'll send you a link to reset your password."
          />
          <StyledInputBox>
            <Input
              type="text"
              label="Email"
              name="name"
              ref={mailRef}
              autofocus
            />
          </StyledInputBox>
          <FormButton isLoading={isLoading} text="Send reset password email" />
        </StyledForm>
        <FormResponse
          text={successText || errorText}
          hasError={errorText !== ""}
        />
        <FormLink url="/auth/login" text="Back to login" />
      </StyledBox>
    </StyledContent>
  )
}
