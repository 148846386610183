import { create } from "zustand"
import { createTrackedSelector } from "react-tracked"

import { State } from "./State"
import { signIn } from "./signIn"
import { signOut } from "./signOut"
import { updateToken } from "./updateToken"

export const useAuthStore = createTrackedSelector(
  create<State>((set) => ({
    isAuthed: false,
    token: undefined,

    // Methods
    signIn: (token) => signIn(set, token),
    signOut: () => signOut(set),
    updateToken: (token) => updateToken(set, token),
  })),
)
