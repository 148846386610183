import {
  StyledEmbed,
  StyledWrapper,
  StyledExcelIcon,
  StyledPowerpointIcon,
  StyledWordIcon,
} from "./DocumentView.styles"
import type { File } from "types"
// TODO: combine these and move to ts-sdk
import { FILE_MIMES } from "constants/common"
import { MIME_TYPE } from "@invenseit/ts-sdk"

type Props = {
  readonly file: File
}

export const DocumentView = ({ file }: Props) => {
  // Check mime type
  let innerContent: JSX.Element | null = null

  if (file.mime_type === FILE_MIMES.PDF) {
    innerContent = (
      <StyledEmbed
        title="document-view"
        src={file.metadata?.url || ""}
        type="application/pdf"
      />
    )
  } else if (
    file.mime_type === MIME_TYPE.EXCEL ||
    file.mime_type === MIME_TYPE.EXCELSPREEDSHEET
  ) {
    innerContent = <StyledExcelIcon />
  } else if (
    file.mime_type === MIME_TYPE.POWERPOINT ||
    file.mime_type === MIME_TYPE.POWERPOINTPRESENTATION
  ) {
    innerContent = <StyledPowerpointIcon />
  } else if (
    file.mime_type === MIME_TYPE.WORD ||
    file.mime_type === MIME_TYPE.WORDDOCUMENT
  ) {
    innerContent = <StyledWordIcon />
  } else if (file.mime_type === FILE_MIMES.POSTSCRIPT) {
    let imageUrl: string | undefined = undefined

    if (file.preview) {
      imageUrl =
        file.preview[6]?.["jpeg"] ||
        file.preview[3]?.["jpeg"] ||
        file.preview[2]?.["jpeg"] ||
        null
    }

    if (!imageUrl) {
      return null
    }

    innerContent = (
      <figure>
        <img src={imageUrl} alt="document-preview" />
      </figure>
    )
  }

  return <StyledWrapper>{innerContent}</StyledWrapper>
}
