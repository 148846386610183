import { create } from "zustand"
import { createTrackedSelector } from "react-tracked"
import { State } from "./State"
import { updateTerm } from "./updateTerm"

export const useSearchStore = createTrackedSelector(
  create<State>((set) => ({
    searchTerm: "",

    /** Methods */

    updateTerm: (term) => updateTerm(set, term),
  })),
)
