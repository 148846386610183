import { FileMetadata } from "types"

export const getImageDimensions = (metadata?: FileMetadata | null) => {
  let imageDimensions = ""
  if (metadata?.width && metadata?.height) {
    imageDimensions = `${metadata.width} x ${metadata.height} px`
    if (metadata?.dpi) {
      imageDimensions += `, ${metadata.dpi} DPI`
    }
  } else {
    return null
  }
  return imageDimensions
}
