export const AUTH_FAILED = "Oops! That combination is not valid"

export enum FILE_MIMES {
  JPEG = "image/jpeg",
  JPG = "image/jpg",
  PDF = "application/pdf",
  POSTSCRIPT = "application/postscript",
  PNG = "image/png",
  M4V = "video/mp4",
  TIFF = "image/tiff",
  WEBP = "image/webp",
  AVIF = "image/avif",
}

// imageTokenSupport is used to see if we need to setup image urls
export const SUPPORTED_IMAGE_THUMBS = [
  FILE_MIMES.PNG,
  FILE_MIMES.JPEG,
  FILE_MIMES.JPG,
  FILE_MIMES.TIFF,
  FILE_MIMES.PDF,
]

export const IMAGE_MIMES = [
  FILE_MIMES.PNG,
  FILE_MIMES.JPEG,
  FILE_MIMES.JPG,
  FILE_MIMES.TIFF,
]
