import { cErr } from "utils"

/**
 *
 * @param url - Direct url to download the file from
 * @param filename - Name of the file to save to disk
 */
export const downloadFile = async (url: string, filename: string) => {
  fetch(url, {
    method: "GET",
  })
    .then((response) => response.blob())
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = blobUrl
      a.download = filename
      document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click()
      a.remove() // afterwards we remove the element again
    })
    .catch((err) => {
      cErr("failed to download file from API", err)
    })
}
