import styled from "styled-components"

const StyledSection = styled.section`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  height: 300px;
  width: 100%;
`

const StyledH1 = styled.h1`
  font-size: 1.7rem;
  line-height: 4rem;
`

const StyledSubText = styled.p`
  font-size: 1rem;
  line-height: 20px;
`

export const MediaBagEmpty = () => (
  <StyledSection>
    <StyledH1>Your media bag is empty</StyledH1>
    <StyledSubText>
      You don&apos;t have anything in your bag. Start browsing to change that!
    </StyledSubText>
  </StyledSection>
)
