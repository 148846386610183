import { GetState, SetState } from "zustand"
import cloneDeep from "lodash/cloneDeep"
import Cookies from "js-cookie"
import { State, MEDIABAG_COOKIE, COOKIE_EXPIRE } from "./State"

export const remove = (
  set: SetState<State>,
  get: GetState<State>,
  fileId: string,
) => {
  const { items } = get()
  const itemsCloned = cloneDeep(items)

  // Check if product variant exist in bag
  const pvIndex = itemsCloned.findIndex((obj) => obj.fileId === fileId)

  if (pvIndex > -1) {
    itemsCloned.splice(pvIndex, 1)

    if (itemsCloned.length > 0) {
      // Delete cookie
      Cookies.remove(MEDIABAG_COOKIE)
    } else {
      // Update cookie
      Cookies.set(MEDIABAG_COOKIE, JSON.stringify(itemsCloned), {
        expires: COOKIE_EXPIRE,
      })
    }

    // Update store
    set({ items: itemsCloned, amount: Object.keys(itemsCloned).length })
  }
}
