type Props = {
  readonly className?: string
}

export const PowerpointIcon = ({ className }: Props) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    enableBackground="new: 0 0 122.88 116.03"
    viewBox="0 0 122.88 116.03"
    fill="#d04223">
    <path d="M.38 12.11 69.16.09l.53-.09v116.03l-.53-.09L.38 104.63l-.38-.06V12.17l.38-.06zm75.91 4.9h43.79c.77 0 1.47.32 1.98.82.51.51.82 1.21.82 1.98v76.75c0 .78-.32 1.5-.84 2.01s-1.23.84-2.01.84H75.84V89.35h34.07V83.2H75.84v-8.1h34.07v-6.15H75.84v-9.82l.71.51c1.32.94 2.79 1.68 4.36 2.18 1.52.48 3.14.74 4.82.74 4.38 0 8.34-1.78 11.21-4.64 2.82-2.82 4.59-6.7 4.64-11h-16.2V30.86a15.768 15.768 0 0 0-8.83 2.92l-.71.51V17.01h.45zm22.97 15.74a15.798 15.798 0 0 0-10.73-4.65v15.48h15.36c-.1-4.23-1.85-8.05-4.63-10.83zM30.91 80.41V63.52h6.67c2.41 0 4.56-.35 6.45-1.05 1.87-.7 3.49-1.75 4.86-3.15s2.39-3.04 3.08-4.91c.69-1.88 1.03-4 1.03-6.37 0-1.61-.16-3.12-.48-4.55-.32-1.42-.79-2.76-1.43-4.01-.63-1.25-1.4-2.36-2.29-3.32-.89-.96-1.91-1.78-3.06-2.45-2.31-1.35-4.97-2.03-7.98-2.03H22.07v48.75h8.84v-.02zm6.85-25.21h-6.84V39.98h6.97c.95.01 1.81.21 2.57.59.76.38 1.41.95 1.96 1.71.54.74.95 1.6 1.21 2.58.27.97.4 2.05.4 3.24 0 1.1-.13 2.08-.39 2.94-.27.88-.67 1.63-1.21 2.26-.54.63-1.21 1.11-2 1.43-.78.32-1.67.47-2.67.47z" />
  </svg>
)
