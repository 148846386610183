import styled from "styled-components"

const StyledSpan = styled.span`
  width: 32px;
  height: 32px;

  border: 4px solid var(--color-grey-100);
  border-bottom-color: var(--color-grey-600);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const DotCircleLoader = () => <StyledSpan />
