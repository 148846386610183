import { forwardRef } from "react"
import { StyledLabel, StyledInput } from "./Input.styles"

type InputProps = {
  readonly autofocus: boolean
  readonly label: string
  readonly name: string
  readonly placeholder?: string
  readonly type: string
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ label, type, name, placeholder, autofocus }, ref) => (
    <div>
      <StyledLabel htmlFor={name}>{label}</StyledLabel>
      <StyledInput
        ref={ref}
        type={type}
        name={name}
        placeholder={placeholder}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autofocus}
      />
    </div>
  ),
)

Input.displayName = "Input"
