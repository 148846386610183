import { GetState, SetState } from "zustand"
import cloneDeep from "lodash/cloneDeep"
import { State } from "./State"

export const setOpen = (
  set: SetState<State>,
  get: GetState<State>,
  uid: string,
) => {
  const { list } = get()
  const clonedList = cloneDeep(list)
  const listIndex = clonedList.indexOf(uid)
  if (listIndex > -1) {
    // Check if uid already exist if so remove it
    clonedList.splice(listIndex, 1)
  } else {
    // Add uid to list
    clonedList.push(uid)
  }
  // TODO: Update cookie
  set({ list: clonedList })
}
