import styled from "styled-components"
import { Link } from "react-router-dom"

const StyledContent = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;

  a {
    color: var(--header-text-color);
    font-size: 0.875rem;
    font-weight: 600;
    text-align: center;
  }
`

type Props = {
  readonly url: string
  readonly text: string
}

export const FormLink = ({ url, text }: Props) => (
  <StyledContent>
    <Link to={url}>{text}</Link>
  </StyledContent>
)
