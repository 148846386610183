import styled from "styled-components"

export const StyledContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: inherit;

  @media (min-width: 1280px) {
    justify-content: flex-end;
  }

  @media (max-width: 768px) {
    padding-top: 2rem;
  }
`

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 140px;

  &:not(:last-child) {
    margin-right: 62px;
  }
`

export const StyledUl = styled.ul`
  list-style: none;
  margin: 0px;
  padding: 0px;
`
