import {
  StyledLi,
  StyledDropdownRow,
  StyledTitleText,
  StyledSubText,
} from "./DropdownItem.styles"

type DropdownItemProps = {
  name: string
  subText?: string

  /** Methods */
  onClick: () => void
}

export const DropdownItem = ({ name, subText, onClick }: DropdownItemProps) => (
  <StyledLi onClick={onClick} key={name}>
    <StyledDropdownRow>
      <StyledTitleText>{name}</StyledTitleText>
      {subText && <StyledSubText>{subText}</StyledSubText>}
    </StyledDropdownRow>
  </StyledLi>
)
