import { SetState } from "zustand"
import type { ORDER } from "types/List"
import { State } from "./State"
import { getListConfig, setListConfigCookie } from "./utils/config"

export const updateOrderBy = (set: SetState<State>, value: ORDER) => {
  // Get config from cookie
  const config = getListConfig()
  config.orderBy = value
  setListConfigCookie(config)
  set({ orderBy: value, documents: [], hasMore: true })
}
