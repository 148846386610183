import orderBy from "lodash/orderBy"
import { Header } from "./atoms"
import { StyledContent, StyledColumn, StyledUl } from "./FooterColumn.styles"
import { ListItem } from "./atoms/ListItem"
import { FooterList } from "./types"

type Props = {
  readonly data: FooterList[]
}

export const FooterColumns = ({ data }: Props) => {
  // Order list by order field
  const dataOrdered = orderBy(data, ["order"], ["asc"])

  const list = dataOrdered.map((obj) => {
    const listItems = obj.list.map((item) => (
      <ListItem key={item.label} label={item.label} url={item.url} />
    ))

    return (
      <StyledColumn key={obj.label}>
        <Header text={obj.label} />
        <StyledUl>{listItems}</StyledUl>
      </StyledColumn>
    )
  })

  return <StyledContent>{list}</StyledContent>
}
