import styled from "styled-components"
import {
  FacebookIcon,
  LinkedInIcon,
  TwitterIcon,
  InstagramIcon,
  YoutubeIcon,
} from "@invenseit/storybook-ui"

export const StyledSection = styled.section`
  background: var(--footer-copywright-background-color);
  position: static;

  padding: 1.5rem 0;
  width: 100%;

  @media (min-width: 768px) {
    padding: 3rem 0;
  }
`

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;

    max-width: 1220px;
    margin: 0px auto;

    width: calc(100% - 4rem);
  }
`

export const StyledText = styled.p`
  color: var(--footer-copywrite-text-color);
  font-size: 0.875rem;
  line-height: 24px;

  @media (max-width: 768px) {
    font-size: 0.8125rem;
  }
`

export const StyledSocialMedia = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;

  a {
    margin-right: 24px;
  }

  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`

export const StyledFacebookIcon = styled(FacebookIcon)`
  display: block;
  height: 1rem;
  width: 1rem;

  @media (min-width: 768px) {
    height: 1.4rem;
    width: 1.4rem;
  }
`
export const StyledLinkedinIcon = styled(LinkedInIcon)`
  display: block;
  height: 1rem;
  width: 1rem;

  @media (min-width: 768px) {
    height: 1.4rem;
    width: 1.4rem;
  }
`
export const StyledInstagramIcon = styled(InstagramIcon)`
  display: block;
  height: 1rem;
  width: 1rem;

  @media (min-width: 768px) {
    height: 1.4rem;
    width: 1.4rem;
  }
`
export const StyledTwitterIcon = styled(TwitterIcon)`
  display: block;
  height: 1rem;
  width: 1rem;

  @media (min-width: 768px) {
    height: 1.4rem;
    width: 1.4rem;
  }
`
export const StyledYoutubeIcon = styled(YoutubeIcon)`
  display: block;
  height: 1rem;
  width: 1rem;

  @media (min-width: 768px) {
    height: 1.4rem;
    width: 1.4rem;
  }
`
