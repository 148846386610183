import styled from "styled-components"

export const StyledItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: row;

  background-color: var(--color-grey-200);
  border-radius: 8px;
  color: var(--color-grey-600);

  padding: 0.25rem 0.25rem 0.25rem 0.5rem;
`

export const StyledText = styled.p`
  padding-right: 0.375rem;
`
