import styled from "styled-components"
import { CheckIcon } from "icons/Check"

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  padding-top: 1rem;
`

export const StyledSection = styled.div`
  padding: 1.25rem 0;

  &:not(:last-child) {
    border-bottom: 1px solid var(--border-color);
  }
`

export const StyledNameTitle = styled.p`
  font-size: 1rem;
  line-height: 20px;
  font-weight: 600;
  word-break: break-all;
`

export const StyledUl = styled.ul`
  list-style: none;
`

export const StyledLi = styled.li`
  padding: 0.2rem 0;
`

export const StyledLiText = styled.p`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 16px;
  word-break: break-all;
`

export const StyledSpan = styled.span`
  font-weight: 400;
`

const StyledActionButton = styled.button`
  letter-spacing: 0.5px;

  border-radius: 4px;
  border: none;
  cursor: pointer;
  height: 44px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  outline: none;
  width: 100%;

  user-select: none;
`

export const StyledAddToBasketBtn = styled(StyledActionButton)`
  background-color: var(--btn-add-to-basket-background-color);
  border: 1px solid var(--border-color);
  color: var(--header-text-color);
  flex: 1;

  align-items: center;
  display: flex;
  justify-content: center;

  transition: background-color 200ms ease-in, border 150ms ease-in;

  &:hover {
    background-color: var(--color-grey-50);
    border: 1px solid var(--border-input-hover);
  }

  &[data-completed="true"] {
    background: #059669;
    border: 1px solid #059669;
  }
`

export const StyledDownloadBtn = styled(StyledActionButton)`
  background-color: var(--company-color);
  border: 1px solid var(--company-color);
  color: #fff;
  letter-spacing: 0.5px;
  flex: 1;

  &:after {
    content: "";
    animation: spin 500ms linear infinite;
    position: absolute;
    top: calc(50% - 0.5rem);
    left: calc(50% - 0.5rem);
    width: 1rem;
    height: 1rem;
    border: 2px solid #fff;
    border-top-color: transparent;
    border-left-color: transparent;
    border-radius: 50%;
    opacity: 0;
  }
`

export const StyledLocationPath = styled.p`
  color: #374151;
  font-size: 0.875rem;
  line-height: 20px;
  font-weight: 400;
  word-break: break-all;
`

export const StyledActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`

export const StyledCheckMark = styled(CheckIcon)`
  height: 30px;
  width: 30px;

  path {
    stroke: #fff;
  }
`
