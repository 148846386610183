import { StyledCenter, StyledMovieBox, StyledIconText } from "./IconView.styles"

type Props = {
  readonly fileExtension: string
}

export const IconView = ({ fileExtension }: Props) => (
  <StyledCenter>
    <StyledMovieBox>
      <StyledIconText>{fileExtension}</StyledIconText>
    </StyledMovieBox>
  </StyledCenter>
)
